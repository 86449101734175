import React, {useState} from 'react';
import {SearchNav} from './SearchNav';
import {FeedbackModal} from './FeedbackModal';
import {Hidden} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';


import {sendAmplitudeData} from '../utilities/amplitude';
import BottomNav from '../nav/BottomNav';
import Divider from '@material-ui/core/Divider';
import {MobileBottomNav} from '../nav/MobileBottomNav';
import { useLocation } from '@reach/router';
import {getCuisineDrawerOpenSelector} from '../store/venuesSlice';
import {useSelector} from 'react-redux';

interface StyleProps {
    pageOpen: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        uiContainer: {
            height: '100%',
            width: '100%',
            position: 'fixed',
            zIndex: 5,
            left: 0,
            top: 0,
            overflowX: 'hidden', /* Disable horizontal scroll */
            overflowY: 'hidden', /* Disable vertical scroll */
            pointerEvents: 'none', /* Don't intercept mouse clicks */
        },
        searchNav: {
            pointerEvents: 'auto',
            justifyContent: 'center',
            position: 'fixed',
            right: 0,
            [theme.breakpoints.up('md')]: {
                width: (props: StyleProps) => props.pageOpen ? '75%' : '100%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            paddingLeft: '5%',
            paddingRight: '5%',
        },
        desktopBottomNav: {
            width: 400,
            pointerEvents: 'auto',
        },
        desktopBottomNavHolder: {
            position: 'fixed',
            bottom: 0,
            right: 0,
            height: 56,
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                width: (props: StyleProps) => props.pageOpen ? '75%' : '100%',
            },
        }
    }),
);

function UiOverlay(): JSX.Element {
    const location = useLocation();
    const [feedbackOpen, setFeedbackOpen] = React.useState(false);
    const [selectedFeedbackTab, setSelectedFeedbackTab] = useState<number>(0);
    const cuisinesDrawerOpen = useSelector(getCuisineDrawerOpenSelector);
    const pageOpen = location.pathname.match('/cuisines/.*') ? cuisinesDrawerOpen : location.pathname !== '/';

    const classes = useStyles({
        pageOpen
    });

    const openFeedbackModal: (tab?: number) => void = (tab) => {
        if (tab !== undefined && tab !== null) {
            setSelectedFeedbackTab(tab);
        }

        setFeedbackOpen(true);

        sendAmplitudeData('OpenFeedbackModal', {});
    };

    const handleFeedbackClose = () => {
        setFeedbackOpen(false);
        sendAmplitudeData('CloseFeedbackModal', {});
    };

    return <>
        <div className={classes.uiContainer}>
            <SearchNav className={classes.searchNav} feedbackOnClick={openFeedbackModal}/>
            <FeedbackModal selectedTab={selectedFeedbackTab} setSelectedTab={setSelectedFeedbackTab} open={feedbackOpen} handleClose={handleFeedbackClose}/>
        </div>
        <Hidden smDown>
            <div className={classes.desktopBottomNavHolder}>
                <Divider orientation={'vertical'} flexItem={true}/>
                <BottomNav className={classes.desktopBottomNav}/>
                <Divider orientation={'vertical'} flexItem={true}/>
            </div>
        </Hidden>
        <MobileBottomNav/>
    </>;
}

export {
    UiOverlay
};