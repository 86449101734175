import {createSelector} from '@reduxjs/toolkit';
import {getAllVenuesSelector} from '../venuesSlice';
import Fuse from 'fuse.js';
import {AppThunk} from '../store';
import {mapActionTriggered} from '../mapControlSlice';
import {SearchResultParent} from './SearchResultParent';

const options = {
    includeScore: true,
    threshold: 0.4,
    keys: ['value']
};

export interface LocationSearchComponent {
    value: string
}

const getLocationCandidates = createSelector(
    [getAllVenuesSelector],
    (allVenues) => {

        const result: LocationSearchComponent[] = [...new Set<string>(allVenues?.flatMap((venue) => {
            return venue.simpleAddress
                .toLowerCase()
                .replace(/ *\([^)]*\) */g, '')
                .split(',')
                .map((addressComponent) => {
                    return addressComponent.replace(/[-_0-9/&]/g, '')
                        .replace(/level|shop/g, '')
                        .trim()
                        .replace(/^[a-z] /g, '')
                        .trim();
                })
                .filter((addressComponent) => {
                    return addressComponent.length > 3;
                });
        })).values()].map((location) => ({value: location}));
        return result;
    });

const locationIndexSelector = createSelector(
    [getLocationCandidates],
    (locationCandidates) => {

        return Fuse.createIndex(options.keys, locationCandidates);
    }
);

export const locationSearchEngineSelector = createSelector(
    [locationIndexSelector, getLocationCandidates],
    (index, locationCandidates) => {
        return new Fuse(locationCandidates, options, index);
    }
);

export const fitMapToLocation = (location: string): AppThunk => (dispatch, getState) => {
    const allVenues = getAllVenuesSelector(getState());
    const matchingLocations = allVenues?.filter((venue) => venue.simpleAddress.toLowerCase().includes(location.toLowerCase()))
        .map((venue) => venue.location) || [];
    dispatch(mapActionTriggered({type: 'showAll', locations: matchingLocations}));
};

export interface LocationSearchResult extends SearchResultParent<LocationSearchComponent> {
    score: number,
    item: LocationSearchComponent,
    refIndex: number,
    key: string,
    type: 'LocationSearchResult',
}

export function locationSearch(searchString: string, numResults: number, locationSearchEngine: Fuse<LocationSearchComponent>): LocationSearchResult[] {
    return locationSearchEngine.search(searchString)
        .filter((result) =>
            result && result.score && result.score < 0.4
        )
        .slice(0, numResults)
        .map(({score = 1, item, refIndex}) => ({
            score, item, refIndex, key: item.value + '-location-key', type: 'LocationSearchResult'
        }));
}