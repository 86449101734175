import {createSelector} from '@reduxjs/toolkit';
import {getCuisinesSelector} from '../venuesSlice';
import Fuse from 'fuse.js';
import {SearchResultParent} from './SearchResultParent';
import {nextNearest} from '../../utilities/next-nearest';
import {Cuisine} from '../../shared-types/responses';

const options = {
    includeScore: true,
    threshold: 0.4,
    keys: [
        {name: 'primary', weight: 1},
        {name: 'combined', weight: 1}
    ]
};

const cuisineIndexSelector = createSelector(
    [getCuisinesSelector],
    (allCuisines) => {

        return Fuse.createIndex(options.keys, allCuisines?.map(({primary, secondary}) => ({primary, secondary, combined: primary + ' ' + secondary})) || []);
    }
);

export const cuisineSearchEngineSelector = createSelector(
    [cuisineIndexSelector, getCuisinesSelector],
    (index, cuisines) => {

        const alteredCuisines = cuisines?.map(({primary, secondary}) => ({primary, secondary, combined: primary + ' ' + secondary}));

        return new Fuse(alteredCuisines || [], options, index);
    }
);

export interface GroupedCuisineSearchResult extends SearchResultParent<Cuisine> {
    item: Cuisine
    secondaries: SecondaryCuisineSearchResult[]
    type: 'GroupedCuisineSearchResult'
}

export type SecondaryCuisineSearchResult =  SearchResultParent<Cuisine>


export function cuisineSearch(fuse: Fuse<Cuisine>, searchString: string, numResults: number): GroupedCuisineSearchResult[] {
    const cuisineSearchResults = fuse.search(searchString)
        .filter((result) => result && result.score && result.score < 0.4)
        .slice(0, numResults);

    const cuisinesWithPrimaryScoreResults = cuisineSearchResults.map((cuisine) => {
        const {score = 1, item, refIndex} = cuisine;
        const key = cuisine.item.primary + '/' + (cuisine.item.secondary || 'none') + '-cuisine';

        const firstCuisineInResultsWithSamePrimaryCuisine = cuisineSearchResults.find(
            (firstCuisine) =>
                firstCuisine.item.primary === cuisine.item.primary
        );
        return {
            score: !cuisine.item.secondary? nextNearest(score, -1) : score,
            primaryScore: firstCuisineInResultsWithSamePrimaryCuisine?.score,
            cuisine: item,
            refIndex,
            key
        };
    });

    const sortedCuisinesWithPrimaries = cuisinesWithPrimaryScoreResults.sort(
        (
            {
                score: scoreA = 1, 
                primaryScore: primaryScoreA,
                cuisine: cuisineA,
            }, {
                cuisine: cuisineB,
                score: scoreB = 1, 
                primaryScore: primaryScoreB
            }) => {
            if (primaryScoreA === primaryScoreB) {
                if (cuisineA.primary === cuisineB.primary) {
                    return scoreA - scoreB;
                }
                return cuisineA.primary.localeCompare(cuisineB.primary);
            }

            return (primaryScoreA || scoreA) - (primaryScoreB || scoreB);
        });

    const result = sortedCuisinesWithPrimaries.reduce((acc, {cuisine, score = 1, refIndex, key}) => {
        if (acc.length > 0 && acc[acc.length - 1].item.primary === cuisine.primary) {
            if (cuisine.secondary) {
                acc[acc.length - 1].secondaries.push({item: cuisine, score, refIndex, key});
            }
        } else {
            acc.push({
                item: {primary: cuisine.primary},
                secondaries: cuisine.secondary ? [{item: cuisine, score: nextNearest(score, -1), refIndex, key}] : [],
                score: !cuisine.secondary ? nextNearest(score, 5) : score,
                refIndex,
                key,
                type: 'GroupedCuisineSearchResult'
            });
        }
        return acc;
    },
        [] as GroupedCuisineSearchResult[]
    );
    return result;
}

