import {openNow} from './google-open-now';
import {OpeningHours} from '@googlemaps/google-maps-services-js/dist/common';

function isVenueOpenNow(openingHours: { opening_hours: OpeningHours, utc_offset: number }, currentTime: number): boolean {
    const result = openNow(openingHours, new Date(currentTime));
    return result || false;
}

export {
    isVenueOpenNow
};