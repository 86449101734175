import React from 'react';
import {createStyles, DialogContent, Typography} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {PatreonButton} from './PatreonButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
        },
        dialogContent: {
            fontSize: '0.9rem',
        },
        welcomeImage: {
            width: '100%',
        }
    }),
);

function AboutUsTabContent(): JSX.Element {
    const classes = useStyles();

    return <div>
        <DialogContent dividers className={classes.dialogContent}>
            <img src={'welcome_to_haveyoueaten_compressed.png'} className={classes.welcomeImage}/>
            <Typography className={classes.paper} gutterBottom>Hiya. The map you’ve just been looking at is the result of hundreds of conversations based around the question ‘where do you and your family like to eat’? All the pins you can see are answers to that question, answers that have come from different community groups, chefs, language associations, cultural clubs and literally anyone who was willing to chat to us. </Typography>
            <Typography className={classes.paper} gutterBottom>You won’t find every restaurant, deli and cafe on this map. The map shows eateries that are popular or well-respected in different communities - whether that’s because it’s a popular meeting place, the only place you can get a particular dish, or maybe just because the menu is good value. It’s not perfect, the restaurant scene is ever-changing and so are Sydney’s community groups and the many opinions within them. </Typography>
            <Typography className={classes.paper} gutterBottom>
                What we hope is for this map to be, is not only a fun tool for you to find a delicious meal, but a representation of the great diversity of cultures and cuisines in Sydney. Basically, if you use this map to meet someone new, eat something you’ve never had before, or learn about the city you live in, we’ll be absolutely ecstatic.
            </Typography>
            <Typography variant={'h6'} className={classes.paper} gutterBottom>
                Who we are:
            </Typography>
            <Typography className={classes.paper} gutterBottom>
                Nick Jordan: I’m a freelance food journalist. I’ve spent most of my career writing about Sydney’s many cultural and linguistic communities, and all the places they go to meet and eat. In 2015 I started a Broadsheet column called Local Knowledge about exactly that. I’ve also written the ABC, SBS, Counter Magazine, The Guardian and a bunch of other places. I’m on FBi radio most Wednesday morning and, like Louis, I love to dance.
            </Typography>
            <Typography className={classes.paper} gutterBottom>
                Louis Lepper: I’m a software developer at Atlassian. Outside of work I love food (obviously), and have spent a lot of time hunting for rare fruit in particular. Once I travelled to Borneo specifically for fruit! My favourite is cempedak. These days you’re more likely to find me searching around Cabramatta’s markets. I also love dancing, which is how I met Nick. 💃🍉
            </Typography>
            <Typography variant={'h6'} className={classes.paper} gutterBottom>
                Supporting Have You Eaten:
            </Typography>
            <Typography className={classes.paper} gutterBottom>
                Have You Eaten has no advertising, commercial sponsors or investors. We rely on support from users to cover costs. If you’d like to help, check out our Patreon page here.
                <br/>
                <PatreonButton/>
                <br/>
                Eventually, we’d like to use any extra cash to pay for good quality food journalism, as well as hosting unique food events. For more info on those, check out our socials.
            </Typography>
            <Typography className={classes.paper} gutterBottom>
                To the legendary citizens of Yumtown who’ve supported us already, thank you:
            </Typography>
            <Typography className={classes.paper} gutterBottom>
                Janice Tan
                <br/>
                Stephen Shaul
                <br/>
                Julian Brezniak
                <br/>
                Thomas Calma
                <br/>
                Min-Taec Kim
                <br/>
                Hansen Ding
                <br/>
                Goh Maehashi
            </Typography>
            <Typography className={classes.paper} gutterBottom>
                For anyone who would like to see our privacy policy, <a href={'https://yumtown.com.au/privacy-policy.html'} target="_blank" rel="noopener noreferrer">click here</a>
            </Typography>
            <Typography className={classes.paper} gutterBottom>
                Peace, love and good eating.
            </Typography>
            <Typography className={classes.paper} gutterBottom>
                Nick and Louis.
            </Typography>
        </DialogContent>
    </div>;
}

export default AboutUsTabContent;
