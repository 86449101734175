import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from './store';
import {sendAmplitudeData} from '../utilities/amplitude';

export type GeolocationStatus = 'UNINITIALISED' | 'NOT_SUPPORTED' | 'PERMISSION_DENIED' | 'FAILURE' | 'ACTIVE'

interface GeolocationState {
    watchId?: string;
    position?: GeolocationPosition;
    status: GeolocationStatus;
}

interface SetLocationAction {
    watchId?: number;
    position?: GeolocationPosition;
    status: GeolocationStatus;
}
const initialState: GeolocationState = {
    status: 'UNINITIALISED'
};

const geolocationSlice = createSlice({
    name: 'geolocation',
    initialState,
    reducers: {
        setLocation: (state, action: PayloadAction<SetLocationAction>) => {
            state.position = action.payload.position;
            state.status = action.payload.status;
        },
    },
});

const innerSetLocation = geolocationSlice.actions.setLocation;
const amplitudeLocationResultKey = 'LocationResult';

const setupGeolocation = (): AppThunk => (dispatch, getState) => {
    let id: number | undefined = undefined;
    function success(position: GeolocationPosition) {
        const status = 'ACTIVE';
        dispatch(innerSetLocation({
            status,
            watchId: id,
            // This is super dumb, but for some reason the GeolocationPosition
            // returned from the browser isn't serialisable, so I need to turn it into a
            // plain object.
            position: {
                coords: {
                    accuracy: position.coords.accuracy,
                    altitude: position.coords.altitude,
                    altitudeAccuracy: position.coords.altitudeAccuracy,
                    heading: position.coords.heading,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    speed: position.coords.speed,
                },
                timestamp: position.timestamp,
            },
        }));

        sendAmplitudeData(amplitudeLocationResultKey, {
            status
        });
    }
    
    function error(err: GeolocationPositionError) {
        let status: GeolocationStatus;

        switch (err.code) {
        case err.PERMISSION_DENIED:
            status = 'PERMISSION_DENIED';
            break;
        case err.POSITION_UNAVAILABLE:
            status = 'FAILURE';
            break;
        case err.TIMEOUT:
            status = 'FAILURE';
            break;
        default:
            status = 'FAILURE';
            break;
        }

        dispatch(innerSetLocation({
            status,
            watchId: id,
        }));

        sendAmplitudeData(amplitudeLocationResultKey, {
            status
        });
        
        console.warn('Geolocation error: (' + err.code + '): ' + err.message);
    }
        
    const options = {
        enableHighAccuracy: true,
        timeout: 15000,
        // 20 minutes in ms
        maximumAge: 20 * 60 * 1000
    };
    
    if (navigator.geolocation.watchPosition) {
        const state = getState();
        const currentStateNeedsRefresh = state.geoLocationData.status === 'UNINITIALISED' || 
            state.geoLocationData.status === 'NOT_SUPPORTED' || 
            state.geoLocationData.status === 'FAILURE';
        if (currentStateNeedsRefresh) {
            id = navigator.geolocation.watchPosition(success, error, options);
        }            
    } else {
        const status = 'NOT_SUPPORTED';
        sendAmplitudeData(amplitudeLocationResultKey, {
            status 
        });
        dispatch(innerSetLocation({
            status,
        }));
    }
};

const geolocationReducer = geolocationSlice.reducer;

const getGeolocationSelector: (state: RootState) => GeolocationPosition | null = (state: RootState) => state.geoLocationData.position || null;
const getGeolocationStatusSelector: (state: RootState) => GeolocationStatus = (state: RootState) => state.geoLocationData.status;
const getGeolocationTheoreticallySupported: (state: RootState) => boolean = (state: RootState) =>
    state.geoLocationData.status === 'FAILURE' || state.geoLocationData.status === 'ACTIVE';

export {
    geolocationReducer,
    geolocationSlice,
    getGeolocationSelector,
    getGeolocationStatusSelector,
    setupGeolocation,
    getGeolocationTheoreticallySupported
};
