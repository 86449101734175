import {createStyles, Divider, List, Paper} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import {LocationBasedSearchResult} from './LocationBasedSearchResult';
import {CuisineBasedSearchResult} from './CuisineBasedSearchResult';
import {Theme as DefaultTheme} from '@material-ui/core/styles/createMuiTheme';
import {SearchResult} from '../../store/searchEngines/combinedSearch';
import {getSearchResultsSelector, getSearchStringSelector} from '../../store/uiSlice';

const useStyles = makeStyles<DefaultTheme, Props>(() =>
    createStyles({
        root: {
            marginTop: 4,
            listStyle: 'none',
        },
        listItem: {
            paddingLeft: 8,
            width: 'auto',
        },
        insetDivider: {
            marginLeft: 32,
        },
        resultsList: props => ({
            maxHeight: props.size === 'small' ? '30vh' : props.size === 'medium' ? '55vh' : undefined,
            overflow: 'auto',
        }),
    }),
);

interface Props {
    className?: string,
    size: 'small' | 'medium' | 'full',
}

function CombinedSearchResults({className, size}: Props): JSX.Element {
    const classes = useStyles({size});

    const searchString = useSelector(getSearchStringSelector);
    const combinedSearchResults = useSelector(getSearchResultsSelector);


    if(!searchString) {
        return <div/>;
    }

    const searchResultEls = (combinedSearchResults || []).map((searchResult: SearchResult) => {
        if (searchResult.type === 'GroupedCuisineSearchResult') {
            return <CuisineBasedSearchResult
                key={searchResult.key}
                className={classes.listItem}
                searchResult={searchResult}
            />;
        } else if (searchResult.type === 'LocationSearchResult') {
            return <React.Fragment key={searchResult.key}>
                <LocationBasedSearchResult className={classes.listItem} searchResult={searchResult}/>
                <Divider classes={{inset: classes.insetDivider}} light variant="inset" component="li"/>
            </React.Fragment>;
        }
    });

    return <Paper id={'search-results'} className={[classes.root, className].join(' ')}>
        <List className={classes.resultsList}>
            {searchResultEls}
        </List>
    </Paper>;
}

export {
    CombinedSearchResults
};