import React, {useState} from 'react';
import {Button, createStyles, DialogContent, Slider, TextField, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {sendAmplitudeData} from '../utilities/amplitude';
import {sendFeedback} from '../backend-client';
import {getAccessStatus} from '../utilities/CookieManagement';

const useStyles = makeStyles(() =>
    createStyles({
        nps: {

        },
        freeformFeedback: {

        },
        feedbackTextField: {
            marginBottom: 15
        },
        dialogContent: {
            fontSize: '0.9rem',
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
    }),
);

interface FeedbackTabContentProps{
    onSubmit: () => void,
}

function FeedbackTabContent({onSubmit}: FeedbackTabContentProps): JSX.Element {
    const classes = useStyles();
    const [freeformFeedback, setFreeformFeedback] = useState('');
    const [npsScore, setNpsScore] = useState<number | undefined>(undefined);

    function handleSubmit() {
        sendFeedback(
            freeformFeedback,
            npsScore,
            getAccessStatus().code,
            navigator.userAgent,
            process.env.REACT_APP_GIT_COMMIT,
        ).catch(() => {
            console.error('Failed to send feedback', {
                freeformFeedback,
                accessCode: getAccessStatus().code
            });
        });
        onSubmit();
        sendAmplitudeData('SubmitFeedbackModal', {
            freeformFeedback,
            npsScore
        });
        setFreeformFeedback('');
        setNpsScore(-1);
    }

    const marks = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 10,
            label: '10',
        },
    ];

    return <DialogContent dividers className={classes.dialogContent}>
        <div>
            <Typography gutterBottom>
                Thank you so much for your support.
            </Typography>
            <div className={classes.nps}>
                <p>How likely is it that you would recommend Have You Eaten to a friend or colleague?</p>
                <Slider
                    value={npsScore}
                    onChange={(event, value ) => {
                        setNpsScore(value as number);
                        sendAmplitudeData('SetNpsScore', {value});
                    }}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    marks={marks}
                    min={1}
                    max={10}
                />
            </div>
            <div className={classes.freeformFeedback}>
                <p>Do you have any feedback for us?</p>
                <TextField
                    className={classes.feedbackTextField}
                    id="filled-multiline-static"
                    label="Multiline"
                    value={freeformFeedback}
                    onChange={(e) => {setFreeformFeedback(e.target.value);}}
                    multiline
                    fullWidth
                    rows={4}
                    variant="filled"
                />

            </div>
        </div>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
        </Button>
    </DialogContent>;
}

export default FeedbackTabContent;