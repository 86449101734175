import React, {ReactPortal} from 'react';
import ReactDOM from 'react-dom';

interface UserLocationButtonProps {
    map: google.maps.Map<Element>;
    controlPosition: google.maps.ControlPosition;
}

class MapControlWrapper extends React.Component<UserLocationButtonProps> {
    private el: HTMLDivElement;
    private map: google.maps.Map<Element>;
    private controlPosition: google.maps.ControlPosition;

    constructor(props: UserLocationButtonProps) {
        super(props);
        const {map, controlPosition} = props;
        this.map = map;
        this.controlPosition = controlPosition;
        this.el = document.createElement('div');
    }

    componentDidMount(): void {
        // The portal element is inserted in the DOM tree after
        // the Modal's children are mounted, meaning that children
        // will be mounted on a detached DOM node. If a child
        // component requires to be attached to the DOM tree
        // immediately when mounted, for example to measure a
        // DOM node, or uses 'autoFocus' in a descendant, add
        // state to Modal and only render the children when Modal
        // is inserted in the DOM tree.
        this.map.controls[this.controlPosition].push(this.el);
    }

    componentWillUnmount(): void {
        // Are we sure this will be our custom control?
        this.map.controls[this.controlPosition].pop();
    }

    render(): ReactPortal {
        return ReactDOM.createPortal(
            this.props.children,
            this.el
        );
    }
}

export default MapControlWrapper;