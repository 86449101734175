import {EnhancedStore} from '@reduxjs/toolkit';
import {getCuisineUrl, getQueryParams} from './urlManagement';
import {navigate} from '@reach/router';

export async function loadState(store: EnhancedStore): Promise<void> {
    const {cuisine, venuePlaceId} = getQueryParams();

    // Legacy query param compatibility
    if (location.pathname === '/') {
        if (venuePlaceId) {
            await navigate('/venue/' + venuePlaceId);
        } else {
            await navigate(getCuisineUrl(cuisine || undefined));
        }
    }
}