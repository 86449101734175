import React from 'react';
import {Hidden} from '@material-ui/core';
import {FilterBoxDrawerDesktop} from '../filter-box/FilterBoxDrawerDesktop';
import {FilterBoxDialogMobile} from '../filter-box/FilterBoxDialogMobile';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {navigate, RouteComponentProps} from '@reach/router';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterBox: {
            pointerEvents: 'auto',
        },
    }),
);


function FilterBox(props: RouteComponentProps<Record<string, unknown>>) {
    const classes = useStyles();
    
    return <>
        <Hidden smDown>
            <FilterBoxDrawerDesktop className={classes.filterBox} open={true}/>
        </Hidden>
        <Hidden mdUp>
            <FilterBoxDialogMobile open={true} handleClose={() => navigate('/')}/>
        </Hidden>
    </>;
}

export {
    FilterBox
};