import {Action} from 'redux';
import venuesReducer from './venuesSlice';
import {ThunkAction} from 'redux-thunk';
import {configureStore} from '@reduxjs/toolkit';
import { geolocationReducer } from './geolocationSlice';
import {uiReducer} from './uiSlice';
import {mapControlReducer} from './mapControlSlice';


export const store = createStore();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createStore() {
    return configureStore({
        reducer: {
            mapData: venuesReducer,
            geoLocationData: geolocationReducer,
            uiState: uiReducer,
            mapControl: mapControlReducer,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
                immutableCheck: false,
            }),
    });
}

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
    >;