import BottomNav from './BottomNav';
import {Hidden} from '@material-ui/core';
import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    navBar: {
        width: '100%',
    }
});

function MobileBottomNav(): JSX.Element {
    const classes = useStyles();

    return <Hidden mdUp>
        <BottomNav className={classes.navBar}/>
    </Hidden>;
}

export {
    MobileBottomNav
};
