function init(): void {

    if (!isIOS()) {
        return;
    }

    // document.addEventListener('touchmove', function (event) {
    //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //     // @ts-ignore
    //     if (event.scale !== 1) { event.preventDefault(); }
    // }, false);
    //
    let lastTouchEnd = 0;
    document.addEventListener('touchend', function (event: TouchEvent) {
        const now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
            event.preventDefault();
        }
        lastTouchEnd = now;
    }, false);

    document.addEventListener('gesturestart', function(e: Event) {
        e.preventDefault();
        // special hack to prevent zoom-to-tabs gesture in safari
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.body.style.zoom = 0.99;
    });

    document.addEventListener('gesturechange', function(e: Event) {
        e.preventDefault();
        // special hack to prevent zoom-to-tabs gesture in safari
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.body.style.zoom = 0.99;
    });

    document.addEventListener('gestureend', function(e: Event) {
        e.preventDefault();
        // special hack to prevent zoom-to-tabs gesture in safari
        // document.body.style.zoom = 0.99;
    });
}

function isIOS(): boolean {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}

export {
    init,
    isIOS
};