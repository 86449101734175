import amplitude from 'amplitude-js';
import config from '../config';

initAmplitude();

function initAmplitude(): void {
    amplitude.getInstance().init(config.amplitude.apiKey);
}

function setAmplitudeUserId(userId: string): void {
    amplitude.getInstance().setUserId(userId);
}

function setAmplitudeUserProperties(properties: Record<string, unknown>): void {
    amplitude.getInstance().setUserProperties(properties);
}

function sendAmplitudeData(eventType: string, eventProperties: Record<string, unknown>): void {
    amplitude.getInstance().logEvent(eventType, eventProperties);
}

export {
    initAmplitude,
    setAmplitudeUserId,
    sendAmplitudeData,
    setAmplitudeUserProperties
};
