import React from 'react';
import {RouteComponentProps} from '@reach/router';
import {getMergedDetailedCuisinesSelector} from '../store/venuesSlice';
import { useSelector } from 'react-redux';
import {getCuisineUrl} from '../store/urlManagement';
import {Cuisine, DetailedCuisine} from '../shared-types/responses';

function PageTree(args: RouteComponentProps<Record<string, unknown>>) {
    const allDetailedCuisines: Map<string, Cuisine & Partial<DetailedCuisine>> = useSelector(getMergedDetailedCuisinesSelector);

    const cuisineLinks = [...allDetailedCuisines?.entries()].filter(([key, cuisine]) => {
        return cuisine.content && cuisine.content.trim().length > 0 &&
            key !== 'default';
    }).map(([key, cuisine]) => {
        return `<url>
            <loc>https://haveyoueaten.co${getCuisineUrl(cuisine)}</loc>
        </url>`;
    });

    const pagetree = `
    <?xml version="1.0" encoding="UTF-8"?>\n
        <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
            ${cuisineLinks?.join('\n')}
        </urlset>
    `;

    return <code>
        {pagetree}
    </code>;
}

export {
    PageTree
};