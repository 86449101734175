import React, {Fragment} from 'react';
import {Router} from '@reach/router';
import {CuisineDescriptionPage} from './map-display/cuisine-list-page/CuisineDescriptionPage';
import App from './App';
import {CuisineListPage} from './map-display/cuisine-list-page/CuisineListPage';
import {VenueDrawer} from './map-display/venue-drawer/VenueDrawer';
import {FilterBox} from './map-display/filter-box/FilterBox';
import {PageTree} from './utilities/PageTree';

function PageRouter(): JSX.Element {
    return <Router primary={false} component={Fragment}>
        <App path="/">
            <CuisineListPage path="/cuisines"/>
            <FilterBox path="/filters"/>
            <VenueDrawer path="/venue/:placeId"/>
            <CuisineDescriptionPage path="/cuisines/:primaryCuisine"/>
            <CuisineDescriptionPage path="/cuisines/:primaryCuisine/:secondaryCuisine"/>
        </App>
        <PageTree path='/pagetree'/>
    </Router>;
}

export {
    PageRouter
};

