import React from 'react';
import {createUseStyles} from 'react-jss';
import {Drawer} from '@material-ui/core';
import {VenueDrawerContent} from './VenueDrawerContent';
import {VenueResponse} from '../../shared-types/responses';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
const useStyles = createUseStyles({
    drawer: {
    },
    content: {

    },
});

type Props = {
    className: string | undefined,
    open: boolean,
    venue: VenueResponse,
}

function VenueDrawerDesktop({className, open, venue}: Props): JSX.Element {
    const classes = useStyles();

    return (
        <div className={className}>
            <Drawer
                anchor={'left'}
                open={open}
                variant={'persistent'}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                PaperProps={{
                    style: {
                        width: '25%'
                    }
                }}
            >
                <div className={classes.drawer}>
                    <VenueDrawerContent
                        className={classes.content}
                        venue={venue}
                        scrollable={'whole'}
                    />
                </div>
            </Drawer>
        </div>
    );
}

export {
    VenueDrawerDesktop
};