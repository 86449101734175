import {Badge, BottomNavigation, BottomNavigationAction, Divider} from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import PublicIcon from '@material-ui/icons/Public';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { randomCuisineSelected} from '../store/venuesSlice';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {activeFilterCountSelector, anyFilterActiveSelector} from '../store/searchFilters';
import {navigate, useLocation} from '@reach/router';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            zIndex: 1299,
            backgroundColor: theme.palette.background.paper
        },
        bottomNavigation: {
            marginBottom: 3
        },
        badge: {
            height: 16,
            minWidth: 16,
            width: 16,
        },
        anchorOriginTopRightRectangle: {
            transform: 'scale(1) translate(50%, -35%)',
        }
    }));

interface Props {
    className: string
}

function BottomNav({className: externalClassName}: Props): JSX.Element {
    const location = useLocation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const filterBoxOpen = location.pathname === '/filters';
    const anyFilterActive = useSelector(anyFilterActiveSelector);
    const activeFilterCount = useSelector(activeFilterCountSelector);

    function randomCuisineOnClickHandler() {
        dispatch(randomCuisineSelected());
    }

    async function cuisinesOnClickHandler() {
        await navigate('/cuisines');
    }

    async function filterOnClickHandler() {
        if (filterBoxOpen) {
            await navigate('/');
        } else {
            await navigate('/filters');
        }
    }

    return <div className={externalClassName + ' ' + classes.root}>
        <Divider/>
        <BottomNavigation
            showLabels
            value={anyFilterActive ? 'Filter' : undefined}
            className={classes.bottomNavigation}
        >
            <BottomNavigationAction label="Cuisines" onClick={cuisinesOnClickHandler} icon={<FormatListBulletedIcon />} />
            <BottomNavigationAction label="Random Cuisine" onClick={randomCuisineOnClickHandler} icon={<PublicIcon />} />
            <BottomNavigationAction label="Filter" value={'Filter'} onClick={filterOnClickHandler} icon={
                <Badge classes={{
                    badge: classes.badge,
                    anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle
                }} badgeContent={activeFilterCount} color="primary">
                    <TuneIcon />
                </Badge>
            } />
        </BottomNavigation>
    </div>;
}

export default BottomNav;