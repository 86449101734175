import {createStyles, List, Paper} from '@material-ui/core';
import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Theme as DefaultTheme} from '@material-ui/core/styles/createMuiTheme';
import {CuisineListSecondaryCuisineOption} from './CuisineListSecondaryCuisineOption';
import {CuisineListPrimaryCuisineSection} from './CuisineListPrimaryCuisineSection';
import {CuisineListSecondaryCuisineSection} from './CuisineListSecondaryCuisineSection';
import {Cuisine} from '../../shared-types/responses';

export interface AutocompleteGroupedOption<T = string> {
    key: number;
    index: number;
    group: string;
    options: T[];
}

interface StyleProps {
    size: 'small' | 'medium' | 'full',
}

const useStyles = makeStyles<DefaultTheme, StyleProps>((theme: Theme) =>
    createStyles({
        root: {
            marginTop: 4,
        },
        resultsList: props => ({
            maxHeight: props.size === 'small' ? '30vh' : props.size === 'medium' ? '55vh' : undefined,
            overflow: 'auto',
        }),
        paper: {
            border: '1px solid',
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
        },
        searchButtons: {
            margin: 4,
            textTransform: 'none',
            minWidth: 90,
        },
        /* Styles applied to the group's label elements. */
        groupLabel: {
            backgroundColor: theme.palette.background.paper,
            top: -8,
        },
        /* Styles applied to the group's ul elements. */
        groupUl: {
            padding: 0,
        },
    }),
);

interface Props {
    groupedOptions: AutocompleteGroupedOption<Cuisine>[],
    listboxProps: unknown,
    size: 'small' | 'medium' | 'full',
    className?: string,
}

function CuisineList({groupedOptions, listboxProps, size, className}: Props): JSX.Element {
    const classes = useStyles({size});

    const cuisineEls = groupedOptions.map((option: AutocompleteGroupedOption<Cuisine>) => {
        const secondaryCuisines = option.options.filter((cuisine) => !!cuisine.secondary);
        const primaryCuisine: Cuisine = {
            primary: option.group
        };
        return <li key={primaryCuisine.primary}>
            <CuisineListPrimaryCuisineSection
                cuisine={primaryCuisine}
            />
            {secondaryCuisines.length > 0 ?
                <CuisineListSecondaryCuisineSection listboxProps={listboxProps}>
                    {secondaryCuisines.map((cuisine: Cuisine) =>
                        <CuisineListSecondaryCuisineOption
                            key={cuisine.primary + cuisine.secondary + '-option'}
                            cuisine={cuisine}
                        />
                    )}
                </CuisineListSecondaryCuisineSection>
                : null
            }
        </li>;
    });

    return cuisineEls.length > 0 ?
        <Paper
            id="search-results"    
            className={[classes.root, className].join(' ')}
        >
            <List className={classes.resultsList}>
                {cuisineEls}
            </List>
        </Paper> : <div id="search-results"/>;
}

export {
    CuisineList
};