import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from './store';
import {sendAmplitudeData} from '../utilities/amplitude';
import {
    getCuisineUrl,
    getQueryParams,
} from './urlManagement';
import Immutable, {Set as ImmutableSet} from 'immutable';
// Legacy - remove this
import initialVenueLoad from './initialVenueLoad.json';

import venueJSON from '../cms-data/venues.json';
import cuisineJSON from '../cms-data/cuisines.json';

import {
    getCombinedFilters,
    getOrCreateVenueTypeFilter,
    SearchFilter,
    SearchFilterParent,
    VenueType
} from './searchFilters';
import {cuisineEquals, selectRandom} from '../utilities/misc-utilities';
import {mapActionTriggered} from './mapControlSlice';
import {navigate} from '@reach/router';
import {
    AllVenuesResponse,
    Cuisine,
    CuisineResponse,
    DetailedCuisine,
    StatusResponse,
    VenueResponse
} from '../shared-types/responses';

interface VenuesState {
    venues: VenueResponse[] | null;
    venueMap: Record<string, VenueResponse>;
    cuisines: Cuisine[] | undefined;
    cuisineDetails: DetailedCuisine[];
    cuisineDrawerOpen: boolean;
    loadTime: number;
    searchedCuisine: Cuisine | null;
    searchFilters: SearchFilter[];
}

function getInitialCuisine(): Cuisine | null {
    const {cuisine} = getQueryParams();
    if (cuisine) {
        sendAmplitudeData('cuisineLoadedFromQueryParams', {cuisine});
        return cuisine;
    } else {
        return null;
    }
}

interface SelectCuisineAction {
    cuisine: Cuisine | null;
}

const initialLoad = processAllVenuesResponse(initialVenueLoad as AllVenuesResponse);

export const initialVenueState: VenuesState = {
    cuisines: initialLoad.cuisines,
    cuisineDetails: [],
    venues: initialLoad.venues,
    venueMap: initialLoad.venueMap,
    cuisineDrawerOpen: true,
    loadTime: initialLoad.loadTime || 0,
    searchedCuisine: getInitialCuisine(),
    searchFilters: [],
};

interface ReceiveVenuesAction {
    venues: VenueResponse[],
    cuisines: Cuisine[],
    loadTime?: number,
}

interface ReceiveDetailedCuisinesAction {
    cuisines: DetailedCuisine[],
}

type BackendStatusReceivedAction = StatusResponse

interface AddFilterAction {
    filter: SearchFilter
}

interface RemoveFilterAction {
    filter: SearchFilterParent
}

export const venuesSlice = createSlice({
    name: 'venues',
    initialState: initialVenueState,
    reducers: {
        addFilter: (state, action: PayloadAction<AddFilterAction>) => {
            state.searchFilters.push(action.payload.filter);
        },
        removeFilter: (state, action: PayloadAction<RemoveFilterAction>) => {
            state.searchFilters = state.searchFilters.filter((filter) => {
                return !(filter.type === action.payload.filter.type && filter.id === action.payload.filter.id);
            });
        },
        allFiltersCleared: (state) => {
            state.searchFilters = [];
        },
        closeCuisineDrawer: (state) => {
            state.cuisineDrawerOpen = false;
        },
        openCuisineDrawer: (state) => {
            state.cuisineDrawerOpen = true;
        },
        receiveVenues: (state, action: PayloadAction<ReceiveVenuesAction>) => {
            state.loadTime = action.payload.loadTime || 0;
            state.venues = action.payload.venues;
            state.venueMap = Object.fromEntries(action.payload.venues.map((venue) =>
                [venue.googlePlaceId, venue]
            ));
            state.cuisines = action.payload.cuisines;
        },
        receiveDetailedCuisines: (state, action: PayloadAction<ReceiveDetailedCuisinesAction>) => {
            state.cuisineDetails = action.payload.cuisines;
        },
        selectCuisine: (state, action: PayloadAction<SelectCuisineAction>) => {
            state.searchedCuisine = action.payload.cuisine;
        },
    },
});

const { receiveVenues, receiveDetailedCuisines } = venuesSlice.actions;
export const receiveVenuesForTesting = venuesSlice.actions.receiveVenues;

export const allFiltersCleared = venuesSlice.actions.allFiltersCleared;

interface ProcessedAllVenuesResponse {
    loadTime: number | undefined;
    venueMap: Record<string, VenueResponse>;
    venues: VenueResponse[];
    cuisines: Cuisine[]
}

function processAllVenuesResponse(allVenuesResponse: AllVenuesResponse): ProcessedAllVenuesResponse {
    const cuisinesISet = ImmutableSet<Cuisine>(Immutable.fromJS(allVenuesResponse.venues.flatMap(venue => venue.cuisines)));
    const cuisines = Array.from(
        cuisinesISet.toJS()
    ).sort((cuisineA, cuisineB) => {
        const sortByPrimary = cuisineA.primary.localeCompare(cuisineB.primary);
        if (sortByPrimary !== 0) {
            return sortByPrimary;
        }

        const secondaryCuisineA = cuisineA.secondary || '';
        const secondaryCuisineB = cuisineB.secondary || '';

        return secondaryCuisineA.localeCompare(secondaryCuisineB);
    });

    const venueMap: Record<string, VenueResponse> = Object.fromEntries(allVenuesResponse.venues.map((venue) => [venue.googlePlaceId, venue]));

    return {cuisines, venues: Object.values(venueMap), loadTime: allVenuesResponse.loadTime, venueMap};
}


// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const fetchAllVenues = (): AppThunk => async (dispatch, getState) => {
    try {
        const allVenuesResponse = venueJSON as unknown as AllVenuesResponse;

        const {cuisines, venues, loadTime} = processAllVenuesResponse(allVenuesResponse);

        const state = getState();
        const currentLoadtime = state.mapData.loadTime;

        if ((loadTime || 0) > currentLoadtime) {
            dispatch(receiveVenues({
                venues,
                cuisines,
                loadTime,
            }));
        } else {
            console.warn('data from server was more stale than current data. (Or was in a partial state');
        }
    } catch(e) {
        console.error('Failed to fetch venues from the backend - operating off stale data');
    }
};

export const fetchCuisines = (): AppThunk => async (dispatch, getState) => {
    try {
        const cuisinesResponse = cuisineJSON as unknown as CuisineResponse;

        dispatch(receiveDetailedCuisines({
            cuisines: cuisinesResponse,
        }));
    } catch(e) {
        console.error('Failed to fetch cuisine details from the backend');
    }
};

export interface NewCuisine {
    primary: string,
    secondaryCuisines: string[]
}

export const getVenueMapSelector: (state: RootState) => Record<string, VenueResponse> = (state: RootState) =>
    state.mapData.venueMap;

export const getCuisineDrawerOpenSelector: (state: RootState) => boolean = (state: RootState) => state.mapData.cuisineDrawerOpen;
export const getLoadTimeSelector: (state: RootState) => number = (state: RootState) => state.mapData.loadTime;
export const getAllVenuesSelector: (state: RootState) => VenueResponse[] | null = (state: RootState) => state.mapData.venues;
export const getCuisinesSelector: (state: RootState) => Cuisine[] | undefined = (state: RootState) => state.mapData.cuisines;
export const getMergedDetailedCuisinesSelector: (state: RootState) => Map<string, (Cuisine & Partial<DetailedCuisine>)> = (state: RootState) => {
    function getCuisineKey(cuisineName: string) {
        return cuisineName.replace(/\s/g, '').toLowerCase().trim();
    }

    const detailedCuisineMapPairs: [string, DetailedCuisine][] = state.mapData.cuisineDetails.map((detailedCuisine: DetailedCuisine) => {
        return [getCuisineKey(detailedCuisine.title), detailedCuisine];
    });

    const detailedCuisineMap = new Map(detailedCuisineMapPairs);

    const result: [string, (Cuisine & Partial<DetailedCuisine>)][] | undefined = state.mapData?.cuisines?.map((cuisine) => {
        const simpleCuisineKey = cuisine.secondary ? cuisine.secondary : cuisine.primary;
        const detailedCuisine: DetailedCuisine | undefined = detailedCuisineMap.get(getCuisineKey(
            cuisine.secondary ? cuisine.secondary : cuisine.primary
        ));
        return [simpleCuisineKey, {
            ...cuisine,
            ...detailedCuisine
        }];
    });

    const defaultCuisine: Cuisine & Partial<DetailedCuisine> = {
        content: detailedCuisineMap.get('default')?.content || 'More information coming soon',
        primary: 'default',
    };

    return new Map([
        ...(result || []), 
        ['default', defaultCuisine]
    ]);
};
export const getNewCuisinesSelector: (state: RootState) => NewCuisine[] | undefined = (state: RootState) => {
    const primaryCuisines: Cuisine[] | undefined = state.mapData.cuisines?.filter((cuisine) => !cuisine.secondary);
    const secondaryCuisines: Cuisine[] | undefined = state.mapData.cuisines?.filter((cuisine) => !!cuisine.secondary);
    const secondaryCuisineMap: Map<string, string[]> | undefined = secondaryCuisines?.reduce(
        (map, cur) => {
            if (!map.has(cur.primary)) {
                map.set(cur.primary, []);
            }
            map.get(cur.primary)?.push(cur.secondary || '');
            return map;
        },
        new Map<string, string[]>()
    );
    const newCuisines: NewCuisine[] | undefined = primaryCuisines?.map((cuisine: Cuisine) => {
        return {
            primary: cuisine.primary,
            secondaryCuisines: secondaryCuisineMap?.get(cuisine.primary) || [],
        };
    });
    return newCuisines;
};

export const getSearchedCuisineSelector: (state: RootState) => Cuisine | null = (state: RootState) => state.mapData.searchedCuisine;

export const getVenueSearchResults = createSelector(
    [getCombinedFilters, getSearchedCuisineSelector, getAllVenuesSelector],
    (combinedFilters, searchedCuisine, allVenues) => {
        return allVenues?.filter((venue) => {
            return searchedCuisine === null ||
                venue.cuisines.find(cuisine => cuisine.primary === searchedCuisine.primary && cuisine.secondary === searchedCuisine.secondary) ||
                (venue.cuisines.map(cuisine => cuisine.primary).includes(searchedCuisine.primary) && !searchedCuisine.secondary);
        })?.filter(combinedFilters) || null;
    }
);

export const getVenueSearchResultCount = createSelector(
    [getVenueSearchResults],
    (searchResults) => {
        return searchResults?.length || 0;
    }
);

export function venueSearchResultsEqualityFn(
    oldResults: VenueResponse[] | null,
    newResults: VenueResponse[] | null
): boolean {
    if (!oldResults && !newResults) {
        return true;
    }

    if (!oldResults || !newResults) {
        return false;
    }

    if (oldResults.length !== newResults.length) {
        return false;
    }

    for (let i = 0; i < oldResults.length; i++) {
        if (oldResults[i] !== newResults[i]) {
            return false;
        }
    }

    return true;
}

const innerSelectCuisine = venuesSlice.actions.selectCuisine;
const addFilterInternal = venuesSlice.actions.addFilter;
export const openCuisineDrawer = venuesSlice.actions.openCuisineDrawer;
export const closeCuisineDrawer = venuesSlice.actions.closeCuisineDrawer;

export const addFilter = (action: AddFilterAction): AppThunk => dispatch => {
    dispatch(addFilterInternal(action));
    sendAmplitudeData('FilterAdded', {filter: action.filter});
};

export const removeFilter = (action: RemoveFilterAction): AppThunk => dispatch => {
    dispatch(removeFilterInternal(action));
    sendAmplitudeData('FilterRemoved', {filter: action.filter});
};

export const removeFilterInternal = venuesSlice.actions.removeFilter;
export const cuisineSelected = (
    {
        cuisine,
        fitMapToCuisine = false
    }: {
        cuisine: Cuisine | null,
        fitMapToCuisine?: boolean
    }
): AppThunk => async (dispatch, getState) => {
    dispatch(innerSelectCuisine({cuisine}));

    const locations = getVenueSearchResults(getState())?.map((venue: VenueResponse) => (venue.location)) || [];
    if (fitMapToCuisine) {
        dispatch(mapActionTriggered({type: 'showAll', locations}));
    } else {
        dispatch(mapActionTriggered({type: 'expandToIncludeClosest', locations}));
    }

    sendAmplitudeData('selectedCuisine', {cuisine});
};

export const randomCuisineSelected = (): AppThunk => async (dispatch, getState) => {
    const currentSearchedCuisine = getSearchedCuisineSelector(getState());
    const combinedFilters = getCombinedFilters(getState());
    const allVenues = getAllVenuesSelector(getState());
    const filteredVenues = allVenues?.filter(combinedFilters);
    const filteredCuisines: Set<Cuisine> = new Set(
        (filteredVenues || [] as VenueResponse[]).flatMap((venue) => venue.cuisines)
            .filter((cuisine) => {
                return !cuisineEquals(cuisine, currentSearchedCuisine);
            })
    );

    const cuisineToSelect = selectRandom([...filteredCuisines]);

    await navigate(getCuisineUrl(cuisineToSelect || undefined));
};

export const venueTypeFilterAdded = (venueType: VenueType): AppThunk => async (dispatch, getState) => {
    const venueTypeFilter = getOrCreateVenueTypeFilter(venueType)(getState());
    dispatch(addFilter({filter: venueTypeFilter}));
    const locationsToExpandTo = getVenueSearchResults(getState())?.map((venue: VenueResponse) => (venue.location));
    dispatch(mapActionTriggered({type: 'expandToIncludeClosest', locations: locationsToExpandTo || []}));
};

export const venueTypeFilterRemoved = (venueType: VenueType): AppThunk => async (dispatch, getState) => {
    const venueTypeFilter = getOrCreateVenueTypeFilter(venueType)(getState());
    dispatch(removeFilter({filter: venueTypeFilter}));
};


export default venuesSlice.reducer;
