import React, {ForwardedRef, useEffect} from 'react';
import {
    Button,
    Container,
    createStyles,
    IconButton,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {
    getMergedDetailedCuisinesSelector,
} from '../../store/venuesSlice';
import {navigate} from '@reach/router';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import {interactionDisabled, interactionEnabled} from '../../store/mapControlSlice';
import {Cuisine, DetailedCuisine} from '../../shared-types/responses';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: () => ({
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            flex: '1 1 66%',
            minHeight: 0
        }),
        descriptionCard: {
            overflow: 'scroll',
            paddingTop: 0,
            flexGrow: 1
        },
        descriptionCardContent: {
            paddingTop: 0
        },
        bottomSection: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: theme.spacing(2),
        },
        secondaryCuisineList: {
            overflow: 'scroll',
            height: '100%'
        },
        title: {
            fontSize: '1.1rem',
            lineHeight: '1.9',
        },
        closeButton: {
            // position: 'absolute',
            // right: theme.spacing(1),
            // top: theme.spacing(1),
            // padding: '16px 24px',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            color: theme.palette.grey[500],
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        content: {

        },
        footer: {

        },
        searchButtons: {
            margin: 4,
            textTransform: 'none',
            minWidth: 90,
            flexGrow: 1,
            paddingLeft: 20,
            display: 'flex',
            justifyContent: 'flex-start',
        },
    }),
);

interface PathParams {
    primaryCuisine: string,
    secondaryCuisine?: string,
}

async function handleClickAway() {
    await navigate('/');
}

interface Props {
    primaryCuisine: string,
    secondaryCuisine?: string
}

function InnerCuisineDescriptionPageContent({
    primaryCuisine, 
    secondaryCuisine, 
    forwardedRef
}: Props & {
    forwardedRef: ForwardedRef<HTMLDivElement>
}): JSX.Element {
    const classes = useStyles();
    const dispatch = useDispatch();
    const allDetailedCuisines: Map<string, Cuisine & Partial<DetailedCuisine>> = useSelector(getMergedDetailedCuisinesSelector);

    if (!primaryCuisine) {
        return <>Error. Invalid cuisine link</>;
    }

    const mobileView = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));

    useEffect(() => {
        if (!mobileView) {
            console.log('Disabling interaction ');
            dispatch(interactionDisabled());
            return () => {dispatch(interactionEnabled());};
        } else {
            console.log('Not disabling interaction ');
        }
    }, [mobileView]);

    const defaultInfo = allDetailedCuisines.get('default');

    const {title: detailedTitle, content: detailedContent} = allDetailedCuisines.get(secondaryCuisine || primaryCuisine || 'default') || {};
    const title = detailedTitle || secondaryCuisine || primaryCuisine;
    const content = detailedContent || defaultInfo?.content || 'More info coming soon!';

    return <div className={classes.root} ref={forwardedRef}>
        <div className={classes.header}>
            <MuiDialogTitle disableTypography>
                <Typography className={classes.title} variant="h4">
                    {title}
                </Typography>
            </MuiDialogTitle>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClickAway}>
                <CloseIcon />
            </IconButton>
        </div>
        <Divider/>
        <Container className={classes.descriptionCard}>
            <Typography variant="body2" dangerouslySetInnerHTML={{__html: content}}/>
        </Container>
        <div className={classes.bottomSection}>
            <Button size={'small'} variant="contained" color="primary" onClick={() => navigate('/cuisines')}>
                        Back to cuisines
            </Button>
        </div>
    </div>;
}

const CuisineDescriptionPageContent = React.forwardRef<HTMLDivElement, Props>(
    function CuisineDescriptionPageContentForwardRef(props, ref) {
        return <InnerCuisineDescriptionPageContent {...props} forwardedRef={ref} />;
    }
);

export {
    CuisineDescriptionPageContent,
};