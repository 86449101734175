import React, {useState} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {AppBar, Dialog, IconButton, Snackbar, Tab, Tabs} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import CloseIcon from '@material-ui/icons/Close';
import theme from '../theme';
import AboutUsTabContent from './AboutUsTabContent';
import FeedbackTabContent from './FeedbackTabContent';
import FollowTabContent from './FollowTabContent';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabArea: {
            height: '70vh',
        },
        tabPanel: {
            height: '100%',
        },
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            color: theme.palette.grey[500]
        },
        title: {
            fontSize: '1.1rem',
            lineHeight: '1.9',
        },
        appBar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }
    })
);

type Props = {
    open: boolean,
    handleClose: () => void,
    selectedTab: number,
    setSelectedTab: (value: number) => void,
}



function FeedbackModal({open, handleClose, selectedTab, setSelectedTab}: Props): JSX.Element {
    const classes = useStyles();
    const [showSubmissionSuccessToast, setShowSubmissionSuccessToast] = useState(false);

    function onFeedbackSubmit() {
        handleClose();
        setShowSubmissionSuccessToast(true);
    }

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>

                <AppBar className={classes.appBar} position="static" color="default">
                    <Tabs
                        value={selectedTab}
                        onChange={(e, value) => setSelectedTab(value)}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="Tab selection"
                    >
                        <Tab label="Feedback"
                            id="feedback-tab"
                            aria-controls="feedback-tabpanel"
                        />
                        <Tab label="About Us"
                            id="about-us-tab"
                            aria-controls="about-us-tabpanel"
                        />
                        <Tab label="Follow"
                            id="follow-tab"
                            aria-controls="follow-tabpanel"
                        />
                    </Tabs>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={selectedTab}
                    onChangeIndex={(index: number) => setSelectedTab(index)}
                    className={classes.tabArea}
                    containerStyle={{height: '100%'}}
                >
                    <div id={'feedback-tabpanel'} className={classes.tabPanel}  dir={theme.direction}>
                        <FeedbackTabContent onSubmit={() => {onFeedbackSubmit();}}/>
                    </div>
                    <div id={'about-us-tabpanel'} dir={theme.direction}>
                        <AboutUsTabContent/>
                    </div>
                    <div id={'follow-tabpanel'} dir={theme.direction}>
                        <FollowTabContent/>
                    </div>
                </SwipeableViews>
            </Dialog>
            <Snackbar
                open={showSubmissionSuccessToast}
                autoHideDuration={15000}
                onClose={() => {
                    setShowSubmissionSuccessToast(false);
                }}>
                <MuiAlert elevation={6} variant="filled" onClose={() => {setShowSubmissionSuccessToast(false);}} severity="info">
                    Thanks for the feedback! You’re helping us to make a better map.
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

const FEEDBACK_TAB = 0;
const ABOUT_US_TAB = 1;
const FOLLOW_TAB = 2;

export {
    FeedbackModal,
    FEEDBACK_TAB,
    ABOUT_US_TAB,
    FOLLOW_TAB,
};