import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Dialog, Drawer, Hidden} from '@material-ui/core';
import {AutocompleteGroupedOption} from './CuisineList';
import {navigate, RouteComponentProps} from '@reach/router';
import {CuisineListPageContent} from './CuisineListPageContent';
import {Cuisine} from '../../shared-types/responses';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        content: {
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        cuisines: {
            overflow: 'scroll'
        },
        desktopDrawer: {
            height: '100%'
        }
    })
);

type Props = {
    open: boolean,
    handleClose: () => void,
}

function getGroupedOptions(cuisines?: Cuisine[]): AutocompleteGroupedOption<Cuisine>[] {
    return cuisines?.reduce((acc, option, index) => {
        const group = option.primary;

        if (acc.length > 0 && acc[acc.length - 1].group === group) {
            acc[acc.length - 1].options.push(option);
        } else {
            acc.push({
                key: index,
                index,
                group,
                options: [option],
            });
        }

        return acc;
    }, [] as AutocompleteGroupedOption<Cuisine>[]) || [];
}
function CuisineListPage(props: RouteComponentProps<Record<string, unknown>>): JSX.Element {
    const classes = useStyles();

    return <>
        <Hidden smDown> {/* Desktop like */}
            <Drawer
                anchor={'left'}
                open={true}
                variant={'persistent'}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                PaperProps={{
                    style: {
                        width: '25%'
                    }
                }}
            >
                <div className={classes.desktopDrawer}>
                    <CuisineListPageContent/>
                </div>
            </Drawer>
        </Hidden>
        <Hidden mdUp> {/* Mobile like */}
            <Dialog fullScreen onClose={() => {navigate('/');}} aria-labelledby="filter-dialog" open={true}>
                <CuisineListPageContent/>
            </Dialog>
        </Hidden>
    </>;
}

export {
    CuisineListPage,
};