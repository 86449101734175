const dev = {
    env: 'dev',
    publicUrl: 'http://localhost:3000',
    mapbox: {
        accessToken: 'pk.eyJ1IjoibG91aXNsZXBwZXIiLCJhIjoiY2tqZ2J0ZWlqNDl1bTJzbnBqN252b3gzNCJ9.5qHnFqYT5c2RH5N7RMdUHQ'
    },
    amplitude: {
        apiKey: '4136ffe6511e4b44c4d8dd3ead3a879a'
    },
    google: {
        maps: {
            accessToken: 'AIzaSyBXrgaM9TDH58jGpi_3YFtOM56Ga6AHLis'
        }
    },
};

const staging = {
    env: 'staging',
    publicUrl: 'https://staging.haveyoueaten.co',
    mapbox: {
        accessToken: 'pk.eyJ1IjoibG91aXNsZXBwZXIiLCJhIjoiY2toOGg5ZGVpMGl4ZzMwazh2MXNuN3JpeSJ9.re8MWJrNJEnP_0O3Cjjw-w'
    },
    amplitude: {
        apiKey: '36e3a4fa420c58c05a5d55f3fde91ec7'
    },
    google: {
        maps: {
            accessToken: 'AIzaSyCOlS1mbXQsekyNI8PRfFcGNeKFzyA_fKo'
        }
    },
};

const prod = {
    env: 'production',
    publicUrl: 'https://haveyoueaten.co',
    mapbox: {
        accessToken: 'pk.eyJ1IjoibG91aXNsZXBwZXIiLCJhIjoiY2tqZ2JzY3A3MTV3MjJ0cGQza2E1ejBhbCJ9.8nXi2AlP-zLFGmKcWVrdxw'
    },
    amplitude: {
        apiKey: '1184a1e0091759d5a30146491f226037'
    },
    google: {
        maps: {
            accessToken: 'AIzaSyCcH0cGOo-VrD26UrtrMBzMfIDZOIPCOqE'
        }
    },
};

function chooseEnv() {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        return prod;
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
        return staging;
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
        return dev;
    }

    throw new Error('Unknown environment: ' + process.env.REACT_APP_ENVIRONMENT);
}

const config = chooseEnv();

export default {
    // Add common config values here
    ...config
};