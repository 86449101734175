import React, {PropsWithChildren} from 'react';
import {createStyles} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        /* Styles applied to the group's ul elements. */
        secondaryCuisineSection: {
            padding: 0,
            borderLeft: '1px solid black',
            marginLeft: 25,
        },
    }),
);

interface Props {
    listboxProps: unknown
}

function SecondaryCuisineSectionComponent({children, listboxProps}: PropsWithChildren<Props>): JSX.Element {
    const classes = useStyles();
    return <ul className={classes.secondaryCuisineSection} {...listboxProps}>
        {children}
    </ul>;
}

const CuisineListSecondaryCuisineSection = React.memo(
    SecondaryCuisineSectionComponent
);

export {
    CuisineListSecondaryCuisineSection,
};