import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { IconButton, Typography} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import {getCuisinesSelector} from '../../store/venuesSlice';
import {useSelector} from 'react-redux';
import {AutocompleteGroupedOption, CuisineList} from './CuisineList';
import {navigate} from '@reach/router';
import {Cuisine} from '../../shared-types/responses';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        content: {
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        cuisines: {
            overflow: 'scroll'
        }
    })
);

type Props = {
    open: boolean,
    handleClose: () => void,
}

function getGroupedOptions(cuisines?: Cuisine[]): AutocompleteGroupedOption<Cuisine>[] {
    return cuisines?.reduce((acc, option, index) => {
        const group = option.primary;

        if (acc.length > 0 && acc[acc.length - 1].group === group) {
            acc[acc.length - 1].options.push(option);
        } else {
            acc.push({
                key: index,
                index,
                group,
                options: [option],
            });
        }

        return acc;
    }, [] as AutocompleteGroupedOption<Cuisine>[]) || [];
}
function CuisineListPageContent(): JSX.Element {
    const classes = useStyles();
    const allCuisines = useSelector(getCuisinesSelector);

    const groupedOptions: AutocompleteGroupedOption<Cuisine>[] = getGroupedOptions(allCuisines);

    return (
        <div className={classes.root}>
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">
                            Cuisines
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => {navigate('/');}}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <Divider/>
            <CuisineList
                className={classes.cuisines}
                groupedOptions={groupedOptions}
                listboxProps={[]}
                size={'full'}
            />
        </div>
    );
}

export {
    CuisineListPageContent,
};