import React from 'react';
import { createStyles, ListSubheader} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {navigate} from '@reach/router';
import {Cuisine} from '../../shared-types/responses';
import {openCuisineDrawer} from '../../store/venuesSlice';
import {getCuisineUrl} from '../../store/urlManagement';
import {useDispatch} from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchButtons: {
            margin: 4,
            textTransform: 'none',
            minWidth: 90,
            flexGrow: 1,
            paddingLeft: 20,
            display: 'flex',
            justifyContent: 'flex-start',
        },
        /* Styles applied to the group's label elements. */
        groupLabel: {
            backgroundColor: theme.palette.background.paper,
            top: -8,
            fontSize: '1rem',
            fontWeight: 400,
            display: 'flex',
            justifyContent: 'flex-start',
        },
    }),
);

interface Props {
    cuisine: Cuisine,
}

function CuisineListPrimaryCuisineSectionComponent({cuisine}: Props): JSX.Element {
    const classes = useStyles();
    const dispatch = useDispatch();

    return <ListSubheader
        color="primary"
        className={classes.groupLabel}
        component="div"
        onMouseDown={async () => {
            dispatch(openCuisineDrawer());
            await navigate(getCuisineUrl(cuisine));
        }}
    >
        {cuisine.primary}
    </ListSubheader>;
}

const CuisineListPrimaryCuisineSection = React.memo(
    CuisineListPrimaryCuisineSectionComponent,
    ((prevProps, nextProps) => {
        return prevProps.cuisine.secondary == nextProps.cuisine.secondary &&
            prevProps.cuisine.primary == nextProps.cuisine.primary;
    })
);

export {
    CuisineListPrimaryCuisineSection,
};