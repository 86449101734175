import {AllVenuesResponse, CuisineResponse, StatusResponse, SubscriptionResponse} from './shared-types/responses';
import {FeedbackRequestBody} from './shared-types/requests';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || '';
import fetch from 'cross-fetch';

async function httpGetSafe<T>(url: string): Promise<T | 'Request Failed'> {
    try {
        const response = await fetch(url, options('GET'));

        if (!response.ok) {
            return 'Request Failed';
        }

        return await response.json() as Promise<T>;
    } catch (e) {
        console.warn('Failed request: ' + e);
    }
    
    return 'Request Failed';
}

async function httpPost<REQ, RES>(url: string, body: REQ): Promise<RES | undefined> {
    const response = await fetch(url, options('POST', body));
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    if (response.status != 204) {
        return await response.json() as Promise<RES>;
    }
    return undefined;
}

function options<T>(verb: string, data: T | undefined = undefined) {
    return {
        dataType: 'json',
        method: verb,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data ? JSON.stringify(data) : undefined
    };
}

async function getSubscription(rawCode: string): Promise<SubscriptionResponse | 'Request Failed'> {
    const code = rawCode.trim().toLowerCase();
    const url = '/api/subscriptions?' +  new URLSearchParams({
        code,
    });
    return await httpGetSafe<SubscriptionResponse>(url);
}

async function getVenues(): Promise<'Request Failed' | AllVenuesResponse> {
    return await httpGetSafe<AllVenuesResponse>(BACKEND_URL + '/api/venues/all?blocking=true');
}

async function getCuisines(): Promise<'Request Failed' | CuisineResponse> {
    return await httpGetSafe<CuisineResponse>(BACKEND_URL + '/api/cuisines');
}

async function getBackendStatus(): Promise<StatusResponse | 'Request Failed'> {
    return await httpGetSafe<StatusResponse>(BACKEND_URL + '/api/status');
}

async function sendFeedback(
    feedback?: string,
    npsScore?: number,
    subscriptionCode?: string,
    userAgent?: string,
    version?: string
): Promise<void> {
    try {
        await httpPost<FeedbackRequestBody, never>(BACKEND_URL + '/api/feedback', {
            feedback, npsScore, subscriptionCode, userAgent, version
        });
    } catch (e) {
        console.warn('Error when sending feedback: ' + e);
    }

    return;
}

export {
    getVenues,
    sendFeedback,
    getSubscription,
    getBackendStatus,
    getCuisines
};