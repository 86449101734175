import {cuisineSearch, GroupedCuisineSearchResult, SecondaryCuisineSearchResult} from './cuisines';
import {locationSearch, LocationSearchComponent, LocationSearchResult} from './location';
import Fuse from 'fuse.js';
import {Cuisine} from '../../shared-types/responses';

export type SearchResult = LocationSearchResult | GroupedCuisineSearchResult

export function combinedSearch(
    searchString: string,
    cuisineSearchEngine: Fuse<Cuisine>,
    locationSearchEngine: Fuse<LocationSearchComponent>
): SearchResult[] {
    const numberOfResults = 20;
    const locationSearchResults = locationSearch(searchString, numberOfResults, locationSearchEngine);

    const groupedCuisineSearchResults = cuisineSearch(cuisineSearchEngine, searchString, numberOfResults);

    const combinedResults = [...locationSearchResults, ...groupedCuisineSearchResults]
        .sort(({score: scoreA = 1}, {score: scoreB = 1}) => {
            return scoreA - scoreB;
        });

    const topResults = [] as SearchResult[];
    let resultLengths = 0;

    while (resultLengths < numberOfResults && topResults.length < combinedResults.length) {
        const newItem = combinedResults[topResults.length] as SearchResult & {secondaries?: SecondaryCuisineSearchResult[]};
        topResults.push(newItem);
        const secondaryItemCount = newItem.secondaries?.length;
        const count = (secondaryItemCount || 0) >= 1 ? (secondaryItemCount || 0) + 1 : 1;
        resultLengths += count;
    }

    return topResults;
}