import React, {Fragment, useEffect, useState} from 'react';
import './App.css';
import { MainMapDisplay } from './map-display/MainMapDisplay';
import {createUseStyles} from 'react-jss';
import {UiOverlay} from './map-display/UiOverlay';
import {
    getAccessStatus,
} from './utilities/CookieManagement';
import {getSubscription} from './backend-client';
import {sendAmplitudeData, setAmplitudeUserProperties} from './utilities/amplitude';
import {IntroModal} from './prototype/IntroModal';
import {getFirstTimeUser, sleep} from './utilities/misc-utilities';
import * as Sentry from '@sentry/react';
import { LoadScript } from '@react-google-maps/api';
import config from './config';
import {RouteComponentProps, useLocation} from '@reach/router';
import {useDispatch, useSelector} from 'react-redux';
import {getMapInteractionEnabledSelector} from './store/mapControlSlice';
import {Helmet} from 'react-helmet';
import {SubscriptionResponse} from './shared-types/responses';
import {closeCuisineDrawer, getCuisineDrawerOpenSelector} from './store/venuesSlice';

const useStyles = createUseStyles({
    app: {
        display: 'flex',
        flexDirection: 'column',
    }
});

function App({children}: RouteComponentProps<Record<string, any>>): JSX.Element {
    const classes = useStyles();
    const accessStatus = getAccessStatus();
    const [introModalOpen, setIntroModalOpen] = useState(false);
    const [introModalShown, setIntroModalShown] = useState(false);
    const interactionEnabled = useSelector(getMapInteractionEnabledSelector);
    const location = useLocation();
    const cuisineDrawerOpen = useSelector(getCuisineDrawerOpenSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (cuisineDrawerOpen && !location.pathname.includes('cuisine')) {
            dispatch(closeCuisineDrawer());
        }
    }, [location]);

    async function refreshExistingSubscription(code: string) {
        let subscription: SubscriptionResponse | 'Request Failed' = 'Request Failed';
        let tries = 0;
        while (subscription === 'Request Failed' && tries < 10) {
            subscription = await getSubscription(code);
            tries++;
        }

        if (subscription === 'Request Failed') {
            console.error('Failed to refresh subscription');
            return;
        }

        if (subscription.status !== 'HAS_ACCESS') {
            console.warn('Access code ' + code + ' expired');
        }

        sendAmplitudeData(
            'subscriptionRefresh',
            {oldStatus: accessStatus, newStatus: subscription.status}
        );
    }

    useEffect(() => {
        if (!accessStatus.hasAccess && !!accessStatus.code) {
            refreshExistingSubscription(accessStatus.code);
        }

        if (accessStatus.code) {
            Sentry.setContext('subscriptionCode', {
                code: accessStatus.code,
                hasAccess: accessStatus.hasAccess,
                until: accessStatus.until,
            });
            setAmplitudeUserProperties({accessStatus});
        }

        const isFirstTimeUser = getFirstTimeUser();
        if (!introModalShown) {
            if (isFirstTimeUser === undefined) {
                console.error('First time user is undefined, so showing the intro modal by default. This should never happen');
                setIntroModalOpen(true);
                setIntroModalShown(true);
            } else if (isFirstTimeUser) {
                setIntroModalOpen(true);
                setIntroModalShown(true);
            }
        }
    });
    
    return (
        <div className={classes.app}>
            <Helmet>
                <title>Have You Eaten</title>
                <meta
                    name="description"
                    content="An interactive map of Sydney cuisines. Search for Sichuan, Sudanese, Southern Thai food, or any other cuisine, and find restaurants popular in those communities."
                />
            </Helmet>
            <LoadScript googleMapsApiKey={config.google.maps.accessToken}>
                <MainMapDisplay/>
            </LoadScript>
            <IntroModal
                open={introModalOpen}
                handleClose={async () => {
                    setIntroModalOpen(false);
                    await sleep(350);
                }}
            />
            {interactionEnabled && <UiOverlay/>}
            {children && React.cloneElement(children, {component: Fragment})}
        </div>
    );
}

export default App;
