/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function Y8(a) {
    this.g = a;
}

function $8(a, b) {
    this.g = a;
    this.endTime = b;
}

Y8.prototype.compare = function(a) {
    a = a.g;
    return this.g === a ? 0 : this.g < a ? -1 : 1;
};

$8.prototype.includes = function(a) {
    return 0 <= a.compare(this.g) && 0 > a.compare(this.endTime);
};

function Z8(a, b) {
    let c = a.time;
    return new Y8((1440 * a.day + 60 * parseInt(c.substring(0, 2), 10) + parseInt(c.substring(2, 4), 10) - b + 10080) % 10080);
}

function innerTwo(a) {
    a = void 0 === a ? new Date : a;
    return new Y8(1440 * a.getUTCDay() + 60 * a.getUTCHours() + a.getUTCMinutes());
}

function innerThree(periods, utc_offset) {
    let c = [];
    periods.forEach(function (period) {
        period = new $8(Z8(period.open, utc_offset), Z8(period.close, utc_offset));
        if (0 > period.endTime.compare(period.g)) {
            let e = new $8(new Y8(0), period.endTime);
            c.push(new $8(period.g, new Y8(10080)));
            c.push(e);
        } else
            c.push(period);
    });
    return c;
}

export function openNow(a, date) {
    date = void 0 === date ? new Date : date;
    const periods = a.opening_hours.periods;
    const utc_offset = a.utc_offset;

    if (periods && null != utc_offset) {
        if (0 === periods.length)
            return false;
        if (1 === periods.length && !periods[0].close && periods[0].open && 0 === periods[0].open.day && '0000' === periods[0].open.time)
            return true;

        let d = innerTwo(date);

        return innerThree(periods, utc_offset).some(function (e) {
            return e.includes(d);
        });
    }
}

