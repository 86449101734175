import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {Dialog, DialogContent, IconButton, Typography} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import {PatreonButton} from '../map-display/PatreonButton';
import AppStoreBadges from './AppStoreBadges';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        feedbackButton: {
            display: 'inline-block',
            marginLeft: 5,
            marginRight: 5,
        },
        tallRow: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 10,
        },
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        title: {
            fontSize: '1.1rem',
            lineHeight: '1.9',
        },
        dialogContent: {
            fontSize: '0.9rem',
        },
    }),
);

type Props = {
    open: boolean,
    handleClose: () => void,
}

function IntroModal({open, handleClose}: Props): JSX.Element {
    const classes = useStyles();

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography className={classes.title} variant="h6">
                        Welcome!
                    </Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>

                <DialogContent dividers className={classes.dialogContent}>
                    <Typography gutterBottom>
                        What you’re about to see is a map of Sydney restaurants, cafes, delis and bakeries. Every one of them was chosen based on conversations we’ve had with different community groups in Sydney. You’ll be able to use this map to search for those eateries based on cuisine, opening hours and your location.
                    </Typography>
                    <Typography gutterBottom>
                        If you think this is a great idea, and you’d like to help:
                    </Typography>
                    <PatreonButton buttonText={'Check out our Patreon'}/>
                    <br/>
                    <Typography gutterBottom>
                        If you want to use the app version of this:
                    </Typography>
                    <AppStoreBadges/>
                    <Typography gutterBottom>
                        <i>Peace, love and good eating.</i>
                    </Typography>
                    <Typography gutterBottom>
                        <i>Nick Jordan and Louis Lepper</i>
                    </Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export {
    IntroModal
};