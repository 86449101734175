import MarkerClusterer from '@googlemaps/markerclustererplus';
import React, {MutableRefObject, useEffect, useRef} from 'react';
import {
    getAllVenuesSelector, getVenueMapSelector,
    getVenueSearchResults,
    venueSearchResultsEqualityFn
} from '../store/venuesSlice';
import {useDispatch, useSelector} from 'react-redux';
import {orderByDistance} from 'geolib';
import theme from '../theme';
import {getGeolocationSelector,} from '../store/geolocationSlice';
import {store} from '../store/store';
import usePrevious from '../utilities/usePrevious';
import {
    getCurrentMapActionSelector,
    getMapInteractionEnabledSelector,
    mapActionCompleted
} from '../store/mapControlSlice';
import {VenueResponse} from '../shared-types/responses';
import {navigate, useLocation, WindowLocation} from '@reach/router';

interface Props {
    map: google.maps.Map
}

const defaultMarkerZIndex = 10000;

function getCurrentVenueId(location: WindowLocation): string | undefined {
    const regexMatch = location.pathname.match(`/venue/([^/]*)`);

    if (!regexMatch) return undefined;
    if (regexMatch.length < 2) return undefined;

    return regexMatch[1];
}

function MainMapManipulator({map}: Props): JSX.Element {
    const location = useLocation();

    const selectedVenueId = getCurrentVenueId(location);
    const venuesToDisplay: VenueResponse[] = useSelector(getVenueSearchResults, venueSearchResultsEqualityFn) || [];
    const allVenues = useSelector(getAllVenuesSelector) || [];
    const venueMap = useSelector(getVenueMapSelector);

    const currentlySelectedVenue = selectedVenueId ? venueMap[selectedVenueId] : undefined;
    const previousSelectedVenue = usePrevious(currentlySelectedVenue);

    const allVenueMarkers = useRef(new Map<string, google.maps.Marker>());
    const markerClusterer = useRef<MarkerClusterer>(new MarkerClusterer(
        map,
        [],
        {
            minimumClusterSize: 10,
            styles: [
                ...[1, 2, 3, 4, 5].map(() => ({
                    textColor: 'black',
                    url: '/cluster.png',
                    height: 52,
                    width: 53,
                    anchorText: [20, 0] as [number, number],
                }))
            ],
            maxZoom: 15
        }
    ));
    const userLocation: GeolocationPosition | null = useSelector(getGeolocationSelector);
    const userLocationMarker: MutableRefObject<google.maps.Marker | null> = useRef(null);
    const dispatch = useDispatch();

    const mapAction = useSelector(getCurrentMapActionSelector);
    const interactionEnabled = useSelector(getMapInteractionEnabledSelector);

    //
    // Placing markers / clusters
    //

    useEffect(() => {
        if (!userLocationMarker.current) {
            userLocationMarker.current = createInitialUserLocationMarker();
            return () => {
                userLocationMarker.current?.setMap(null);
            };
        } else {
            userLocationMarker.current?.setMap(map);
        }
    });
    
    useEffect(() => {
        console.debug('Map reference changed.');
        markerClusterer.current.setMap(map);
    }, [map]);
    
    useEffect(() => {
        console.debug('All venues have been refreshed.');
        markerClusterer.current.clearMarkers();

        for (const marker of allVenueMarkers.current?.values()) {
            marker.setMap(null);
        }
        
        allVenueMarkers.current.clear();

        allVenueMarkers.current = new Map<string, google.maps.Marker>(
            allVenues.map((venue) => {
                const primaryType = venue.type && venue.type[0];
                const initialVenuesToDisplay = new Map(venuesToDisplay.map((venue) => [venue.googlePlaceId, venue]));
                const currentlySelected = selectedVenueId === venue.googlePlaceId;

                const marker = new window.google.maps.Marker({
                    position: venue.location,
                    zIndex: defaultMarkerZIndex,
                    icon: {
                        url: typeToPinUrl(primaryType),
                        scaledSize:
                                        currentlySelected ?
                                            new google.maps.Size(54 * 0.6, 86 * 0.6) :
                                            new google.maps.Size(54 * 0.4, 86 * 0.4)
                    },
                    map: initialVenuesToDisplay.get(venue.googlePlaceId) ? map : undefined,
                });

                marker.addListener('click', async () => {
                    if (store.getState().mapControl.interactionEnabled) {
                        await navigate('/venue/' + venue.googlePlaceId);
                    }
                });
                
                return [venue.googlePlaceId, marker];
            })
        );
        
    }, [allVenues]);

    useEffect(() => {
        const oldSelectedMarker = allVenueMarkers.current.get(previousSelectedVenue?.googlePlaceId || 'none');
        const originalIcon = oldSelectedMarker?.getIcon();
        const newIcon = Object.assign({}, originalIcon, {
            scaledSize: new google.maps.Size(54 * 0.4, 86 * 0.4)
        });
        oldSelectedMarker?.setIcon(newIcon);
        oldSelectedMarker?.setZIndex(defaultMarkerZIndex);

        if (currentlySelectedVenue) {
            const primaryType = currentlySelectedVenue.type && currentlySelectedVenue.type[0];
            const newMarker = allVenueMarkers.current.get(currentlySelectedVenue.googlePlaceId);
            newMarker?.setIcon({
                url: typeToPinUrl(primaryType),
                scaledSize: new google.maps.Size(54 * 0.6, 86 * 0.6)
            });
            newMarker?.setZIndex(defaultMarkerZIndex + 1);
        }
    }, [currentlySelectedVenue]);

    useEffect(() => {
        console.debug('The venues to display has changed.');
        markerClusterer.current.clearMarkers();

        for (const marker of allVenueMarkers.current?.values()) {
            marker.setMap(null);
        }
        const markersToDisplay = venuesToDisplay.flatMap((venue) => {
            const marker = allVenueMarkers.current.get(venue.googlePlaceId);
            return marker ? [marker] : [];
        });
        markerClusterer.current.addMarkers(markersToDisplay);
    }, [venuesToDisplay]);

    useEffect(() => {
        map.setOptions({draggable: interactionEnabled});
    }, [map, interactionEnabled]);

    if (userLocationMarker.current && userLocation && google) {
        const userLoc: google.maps.LatLngLiteral = {
            lat: userLocation.coords.latitude,
            lng: userLocation.coords.longitude,
        };
        userLocationMarker.current.setPosition(userLoc);
        userLocationMarker.current.setMap(map);
    }

    //
    // Control map viewport through user actions
    //

    if (mapAction?.type === 'expandToIncludeClosest') {
        const expanded = expandMapToClosestLocation(map, mapAction.locations);
        if (expanded) {
            setTimeout(() => {
                dispatch(mapActionCompleted());
            }, 50);
        }
    } else if (mapAction?.type === 'trackUserLocation' && userLocation) {
        const userLocationCoords: google.maps.LatLngLiteral = {
            lat: userLocation.coords.latitude,
            lng: userLocation.coords.longitude,
        };
        map.setCenter(userLocationCoords);
    } else if(mapAction?.type === 'centerAndZoomToSingle') {
        map.setCenter(mapAction.location);
        map.setZoom(17);
        setTimeout(() => {
            dispatch(mapActionCompleted());
        }, 50);
    } else if (mapAction?.type === 'showAll') {
        const fitted = fitMapToLocations(map, mapAction.locations);
        if (fitted) {
            setTimeout(() => {
                dispatch(mapActionCompleted());
            }, 50);
        }
    }

    return (
        <div/>
    );
}

function expandMapToClosestLocation(map: google.maps.Map<Element>, locations: google.maps.LatLngLiteral[]): boolean {
    const currentCenter = map.getCenter();

    if (!google) {
        return false;
    }

    if (!currentCenter) {
        return false;
    }

    if (locations.length === 0) {
        return true;
    }

    const venuesSortedByDistance: google.maps.LatLngLiteral[] = orderByDistance({ latitude: currentCenter.lat(), longitude: currentCenter.lng() },
        locations.map((location) => {return {latitude: location.lat, longitude: location.lng};})
    ).map((innerCoords) => {return {lat: innerCoords.latitude, lng: innerCoords.longitude};});

    const venueToInclude: google.maps.LatLngLiteral = venuesSortedByDistance[0];

    const currentBounds = map.getBounds();

    if (!currentBounds) {
        map.panTo(venueToInclude);
        return true;
    }

    if(currentBounds.contains(venueToInclude)) {
        return true;
    }

    const newBounds = currentBounds.union(new google.maps.LatLngBounds(venueToInclude, venueToInclude));
    map.fitBounds(newBounds, 80);
    return true;
}

function fitMapToLocations(map: google.maps.Map<Element>, locations: google.maps.LatLngLiteral[]): boolean {
    const currentCenter = map.getCenter();

    if (!google) {
        return false;
    }

    if (!currentCenter) {
        return false;
    }

    if (locations.length === 0) {
        return true;
    }

    const entireBounds = locations.reduce((acc, cur) => {
        return acc.union(new google.maps.LatLngBounds(cur, cur));
    }, new google.maps.LatLngBounds(locations[0], locations[0]));

    map.fitBounds(entireBounds, 80);
    const currentZoom = map.getZoom();

    // For those cuisines with only one venue
    if (currentZoom > 19) {
        map.setZoom(17);
    }
    return true;
}

function createInitialUserLocationMarker(): google.maps.Marker {
    console.log('making initial location marker');
    const symbol: google.maps.Symbol = {
        fillColor: theme.palette.primary.main,
        strokeColor: theme.palette.background.paper,
        strokeWeight: 2,
        fillOpacity: 100,
        path: google.maps.SymbolPath.CIRCLE,
        scale: 7,
    };

    return new window.google.maps.Marker({
        clickable: false,
        zIndex: 10000,
        icon: symbol
    });
}

function typeToPinUrl(type: string | undefined): string {
    switch ((type || '').toLowerCase()) {
    case 'restaurant':
        return '/restaurant-pin.png';
    case 'cafe':
        return '/cafe-pin.png';
    case 'bakery':
        return '/bakery-alternate-pin.png';
    case 'snack shop':
        return '/takeaway-pin.png';
    case 'sweet shop':
        return '/sweet-shop-pin.png';
    case 'takeaway joint':
        return '/takeaway-pin.png';
    case 'grocer':
        return '/grocery-pin.png';
    case 'deli':
        return '/grocery-pin.png';
    default:
        return '/restaurant-pin.png';
    }
}

export {
    MainMapManipulator
};