import {RootState} from './store';
import {isVenueOpenNow} from '../utilities/openNowCalculator';
import {getSydneyUtcOffsetInMinutes} from '../utilities/misc-utilities';
import {createSelector} from 'reselect';
import {VenueResponse} from '../shared-types/responses';

export interface SearchFilterParent {
    type: string,
    id: number,
    // How filters of the same type are combined.
    combinerLogic: 'OR' | 'AND',
}

interface OpenNow extends SearchFilterParent {
    type: 'Open Now',
    combinerLogic: 'AND',
    // There's only ever one open now filter active at a time.
    atTime: number,
    id: 0,
}

export function getOpenNowFilter(timestamp: number): OpenNow {
    return {
        type: 'Open Now',
        combinerLogic: 'AND',
        id: 0,
        atTime: timestamp,
    };
}

export type VenueType = string;

interface VenueTypeFilter extends SearchFilterParent {
    type: 'VenueType',
    combinerLogic: 'OR',
    venueType: VenueType,
    id: number,
}

let typeFilterUniqueID = 0;

export const getOrCreateVenueTypeFilter: (venueType: VenueType) => (state: RootState) => VenueTypeFilter = (venueType => {
    return (rootState) => {
        const existingFilter: VenueTypeFilter | undefined = rootState.mapData.searchFilters.find((existingFilter => {
            return existingFilter.type === 'VenueType' && existingFilter.venueType === venueType.toLowerCase();
        })) as VenueTypeFilter | undefined;

        const result: VenueTypeFilter = {
            combinerLogic: 'OR',
            id: typeFilterUniqueID,
            type: 'VenueType',
            venueType: venueType.toLowerCase()
        };
        typeFilterUniqueID++;

        return existingFilter || result;
    };
});

function searchFilterTypeToFunction(filter: SearchFilter) {
    if (filter.type === 'Open Now') {
        return openNowFilterFunction(filter.atTime);
    }

    if (filter.type === 'VenueType') {
        return venueTypeFilterFunction(filter.venueType);
    }

    console.warn('Function not found for filter');
    return () => true;
}

function openNowFilterFunction(atTime: number): (venue: VenueResponse) => boolean {
    return (venue: VenueResponse) => {
        if (venue.openingHours) {
            return isVenueOpenNow({
                opening_hours: venue.openingHours,
                utc_offset: getSydneyUtcOffsetInMinutes(atTime)
            }, atTime);
        }
        return false;
    };
}

function venueTypeFilterFunction(venueType: VenueType): (venue: VenueResponse) => boolean {
    return (venue: VenueResponse) => {
        return !!venue.type?.map((type) => type.toLowerCase()).includes(venueType.toLowerCase());
    };
}

export const getSearchFiltersSelector: (state: RootState) => SearchFilter[] | null = (state: RootState) => state.mapData.searchFilters;

function searchFiltersOfSameTypeToFunction(cur: SearchFilter[]): (venue: VenueResponse) => boolean {
    if (cur[0].combinerLogic === 'AND') {
        return cur.reduce((acc, cur) => {
            return (venue) => {
                return acc(venue) && searchFilterTypeToFunction(cur)(venue);
            };
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        }, (venue: VenueResponse) => true);
    } else {
        return cur.reduce((acc, cur) => {
            return (venue) => {
                return acc(venue) || searchFilterTypeToFunction(cur)(venue);
            };
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        }, (venue: VenueResponse) => false);
    }
}

export const getCombinedFilters = createSelector(
    [getSearchFiltersSelector],
    (searchFilters) => {
        const searchFilterMapByType = (searchFilters || []).reduce((acc, cur) => {
            const newEntry = [...(acc.get(cur.type) || []), cur];
            acc.set(cur.type, newEntry);
            return acc;
        }, new Map<VenueType, SearchFilter[]>());
        
        return [...searchFilterMapByType.values()].reduce((acc, cur) => {
            return (venue) => searchFiltersOfSameTypeToFunction(cur)(venue) && acc(venue);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        }, (venue: VenueResponse) => true);
    }
);

export const filterActiveSelector: (state: RootState) => (filter: SearchFilterParent) => boolean | null = (state: RootState) =>
    (filter) => state.mapData
        .searchFilters
        .filter((innerFilter) =>
            innerFilter.type === filter.type && innerFilter.id === filter.id
        ).length > 0;

export const anyFilterActiveSelector: (state: RootState) => boolean = (state: RootState) =>
    state.mapData
        .searchFilters
        .length > 0;

export const activeFilterCountSelector: (state: RootState) => number = (state: RootState) =>
    state.mapData
        .searchFilters
        .length;

export type SearchFilter = OpenNow | VenueTypeFilter