import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {
    getCuisinesSelector,
    getSearchedCuisineSelector,
    cuisineSelected,
} from '../store/venuesSlice';
import {useDispatch, useSelector} from 'react-redux';
import {sendAmplitudeData} from '../utilities/amplitude';
import {
    ClickAwayListener,
    createStyles,
    IconButton,
    Paper,
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import {isMobile, noOpSetTimeout} from '../utilities/misc-utilities';
import {ABOUT_US_TAB, FEEDBACK_TAB} from './FeedbackModal';
import {InputBaseComponentProps} from '@material-ui/core/InputBase/InputBase';
import {
    getSearchResultsBoxOpenSelector, getSearchResultsSelector,
    getSearchStringSelector,
    searchResultsClosed,
    searchResultsOpened, searchStringSet
} from '../store/uiSlice';
import CloseIcon from '@material-ui/icons/Close';
import {CombinedSearchResults} from './search-results/CombinedSearchResults';
import {fitMapToLocation} from '../store/searchEngines/location';
import {Cuisine} from '../shared-types/responses';
import {navigate} from '@reach/router';
import {getCuisineUrl} from '../store/urlManagement';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
        },
        navBar: {
            marginTop: 15,
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginBottom: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }),
);

type Props = {
    className: string | undefined,
    feedbackOnClick: (tab?: number) => void,
}

const inputFieldId = 'input-field';

function clearMobileSoftKeyboard() {
    const inputEl = document.getElementById(inputFieldId);
    inputEl && inputEl.blur();
}

// Used to ensure that we only send analytics events every so often, rather on every keydown.
let inputChangeAnalytics: NodeJS.Timeout = noOpSetTimeout();

function SearchNav({className, feedbackOnClick}: Props): JSX.Element {
    const classes = useStyles();
    const dispatch = useDispatch();

    const cuisines: Cuisine[] | undefined = useSelector(getCuisinesSelector);

    const currentCuisine = useSelector(getSearchedCuisineSelector);

    const searchResults = useSelector(getSearchResultsSelector);
    const inputRef = useRef<HTMLInputElement>(null);
    const firstFocus = useRef(true);

    useEffect(() => {
        clearMobileSoftKeyboard();
    }, [currentCuisine]);

    const searchResultBoxOpen = useSelector(getSearchResultsBoxOpenSelector);

    const searchString = useSelector(getSearchStringSelector);
    const [inputFocused, setInputFocused] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (currentCuisine) {
            dispatch(searchStringSet(currentCuisine?.secondary || currentCuisine?.primary));
        } else {
            dispatch(searchStringSet(''));
        }
    }, [currentCuisine]);

    function menuOnClickHandler() {
        feedbackOnClick(ABOUT_US_TAB);
    }

    async function showAllVenuesOnClickHandler() {
        dispatch(searchStringSet(''));
        dispatch(cuisineSelected({cuisine: null}));
        await navigate('/');
    }

    function handleClickAway() {
        if (currentCuisine) {
            dispatch(searchStringSet(currentCuisine.secondary || currentCuisine.primary));
        }
        dispatch(searchResultsClosed());
        clearMobileSoftKeyboard();
    }

    async function selectTopResult() {
        if (searchResults) {
            const topResult = searchResults[0];
            if (!topResult) {
                return;
            }
            if (topResult.type === 'LocationSearchResult') {
                await navigate('/');
                dispatch(searchStringSet(''));
                dispatch(fitMapToLocation(topResult.item.value));
            } else if (topResult.type === 'GroupedCuisineSearchResult') {
                const topInnerCuisine = [topResult, ...topResult.secondaries].sort((itemA, itemB) => itemA.score - itemB.score)[0];
                await navigate(getCuisineUrl(topInnerCuisine.item));
            }
            dispatch(searchResultsClosed());
        }
    }

    async function inputOnEnter(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if(e.key === 'Enter'){
            await selectTopResult();
        }
    }

    const inputProps: InputBaseComponentProps = {
        id: inputFieldId,
        disabled: cuisines === undefined,
        'aria-autocomplete': 'list',
        'autoCapitalize': 'none',
        'autoComplete': 'off',
        'spellCheck': 'false',
        onChange: ((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newInputValue = event.currentTarget.value;
            dispatch(searchStringSet(newInputValue));
            clearTimeout(inputChangeAnalytics);
            inputChangeAnalytics = setTimeout(() => {
                sendAmplitudeData('searching', {newInputValue});
            }, 1200);
            if (!searchResultBoxOpen) {
                dispatch(searchResultsOpened());
            }
        }),
        onKeyPress: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => inputOnEnter(e),
        value: searchString || '',
    } as InputBaseComponentProps;

    async function searchButtonOnClickHandler() {
        await selectTopResult();
    }

    const showClearSearchButton = searchString === currentCuisine?.secondary ||
        searchString === currentCuisine?.primary;

    const searchButton =
        <IconButton className={classes.iconButton} onClick={searchButtonOnClickHandler} aria-label="search">
            <SearchIcon />
        </IconButton>;

    const clearSearchButton =
        <IconButton className={classes.iconButton} onClick={showAllVenuesOnClickHandler} aria-label="clear-search">
            <CloseIcon />
        </IconButton>;

    const fullSizeSearchResults = !inputFocused || !isMobile();

    function onInputFocus() {
        setInputFocused(true);

        const inputBox = inputRef.current;

        if (inputBox && firstFocus.current && (inputBox.selectionEnd || 0) - (inputBox.selectionStart || 0) === 0) {
            inputBox.select();
        }

        firstFocus.current = false;
    }

    function onInputBlur() {
        setInputFocused(false);
        firstFocus.current = true;
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={className + ' ' + classes.root}>
                <Paper
                    component="form"
                    className={classes.navBar}
                    id={'nav-bar'}
                    onSubmit={(event) => {
                        event.preventDefault();
                        dispatch(searchResultsClosed());
                    }}
                >
                    <IconButton className={classes.iconButton} onClick={menuOnClickHandler} aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <InputBase
                        endAdornment={<div />}
                        inputProps={inputProps}
                        disabled={cuisines === undefined}
                        placeholder="Search by cuisine"
                        onClick={() => dispatch(searchResultsOpened())}
                        onFocus={onInputFocus}
                        onBlur={onInputBlur}
                        fullWidth={true}
                        inputRef={inputRef}
                    />
                    {showClearSearchButton ? clearSearchButton : searchButton}
                    <Divider className={classes.divider} orientation="vertical" />
                    <IconButton
                        color="primary"
                        className={classes.iconButton}
                        aria-label="feedback"
                        onClick={() => {feedbackOnClick(FEEDBACK_TAB);}}
                    >
                        <HelpIcon />
                    </IconButton>
                </Paper>
                {searchResultBoxOpen ? <>
                    <CombinedSearchResults
                        size={fullSizeSearchResults ? 'medium' : 'small'}
                    />
                </> : null}
            </div>
        </ClickAwayListener>
    );
}

export {
    SearchNav
};