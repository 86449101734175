import {Platform} from '../utilities/CookieManagement';
import {Cuisine} from '../shared-types/responses';

const PRIMARY_CUISINE_KEY = 'primary_cuisine';
const SECONDARY_CUISINE_KEY = 'secondary_cuisine';
const PLACE_KEY = 'placeId';
const PLATFORM_KEY = 'platform';

function platformFromParams(urlParams: URLSearchParams): Platform | null {
    return urlParams.get(PLATFORM_KEY) as Platform | null;
}

function clearPlatformQuery(): void {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(PLATFORM_KEY);

    const queryParamString = searchParams.toString().trim();

    const finalString = queryParamString.length > 0 ? '?' + queryParamString : '';

    const newRelativePathQuery = window.location.pathname + finalString;
    history.replaceState(history.state, 'Removing platform param', newRelativePathQuery);
}

function cuisineFromParams(urlParams: URLSearchParams): Cuisine | null {
    const primaryCuisine = urlParams.get(PRIMARY_CUISINE_KEY);
    const secondaryCuisine = urlParams.get(SECONDARY_CUISINE_KEY);

    if (primaryCuisine) {
        if (secondaryCuisine) {
            return {
                primary: primaryCuisine,
                secondary: secondaryCuisine,
            };
        }
        return {
            primary: primaryCuisine,
            secondary: undefined,
        };
    }
    return null;
}

interface QueryParams {
    cuisine: Cuisine | null,
    venuePlaceId: string | null,
    platform: Platform | null,
}

function getQueryParams(): QueryParams {
    const urlParams = new URLSearchParams(window.location.search);

    const cuisine: Cuisine | null = cuisineFromParams(urlParams);
    const venuePlaceId = urlParams.get(PLACE_KEY);
    const platform: Platform | null = platformFromParams(urlParams);

    return {
        cuisine,
        venuePlaceId,
        platform,
    };
}

interface QueryParam {
    key: string,
    value: string | undefined,
}

const clearCuisineQueryParams: QueryParam[] = [
    {key: PRIMARY_CUISINE_KEY, value: undefined},
    {key: SECONDARY_CUISINE_KEY, value: undefined}
];

function cuisineParams(value: Cuisine): QueryParam[] {
    const params: QueryParam[] = [{
        key: PRIMARY_CUISINE_KEY,
        value: value.primary,
    }];

    if (value.secondary) {
        params.push({
            key: SECONDARY_CUISINE_KEY,
            value: value.secondary,
        });
    } else {
        params.push({
            key: SECONDARY_CUISINE_KEY,
            value: undefined,
        });
    }

    return params;
}

function placeIdParam(value: string | undefined): QueryParam {
    return {
        key: PLACE_KEY,
        value
    };
}

function updateQueryParams(params: QueryParam[]): void {
    const searchParams = new URLSearchParams(window.location.search);
    let changed = false;

    for(const param of params) {
        if (!param.value) {
            if (searchParams.get(param.key) !== null) {
                searchParams.delete(param.key);
                changed = true;
            }
        } else if (searchParams.get(param.key) !== param.value) {
            searchParams.set(param.key, param.value);
            changed = true;
        }
    }

    if (changed) {
        const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        history.pushState(null, '', newRelativePathQuery);
    }
}

function getCuisineUrl(cuisine?: Cuisine) {
    if (!cuisine) return '/';
    return cuisine.secondary ?
        `/cuisines/${encodeURIComponent(cuisine.primary)}/${encodeURIComponent(cuisine.secondary)}` :
        `/cuisines/${encodeURIComponent(cuisine.primary)}`;
}

export {
    updateQueryParams,
    cuisineParams,
    placeIdParam,
    getQueryParams,
    clearPlatformQuery,
    clearCuisineQueryParams,
    getCuisineUrl
};