import React from 'react';
import {createUseStyles} from 'react-jss';
import {Drawer} from '@material-ui/core';
import {FilterBoxContent} from './FilterBoxContent';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
const useStyles = createUseStyles({
    drawer: {
        height: '100%',
    },
    content: {

    },
});

type Props = {
    className: string | undefined,
    open: boolean,
}

function FilterBoxDrawerDesktop({className, open}: Props): JSX.Element {
    const classes = useStyles();

    return (
        <div className={className}>
            <Drawer
                anchor={'left'}
                open={open}
                variant={'persistent'}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                PaperProps={{
                    style: {
                        width: '25%'
                    }
                }}
            >
                <div className={classes.drawer}>
                    <FilterBoxContent
                        className={classes.content}
                        isSidebar={true}
                    />
                </div>
            </Drawer>
        </div>
    );
}

export {
    FilterBoxDrawerDesktop
};