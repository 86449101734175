import React, {useEffect} from 'react';
import {Hidden} from '@material-ui/core';
import {
    getVenueMapSelector,
} from '../../store/venuesSlice';
import {VenueDrawerDesktop} from './VenueDrawerDesktop';
import {VenueDrawerMobile} from './VenueDrawerMobile';
import {useDispatch, useSelector} from 'react-redux';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {RouteComponentProps} from '@reach/router';
import {mapActionTriggered} from '../../store/mapControlSlice';

interface StyleProps {
    venueDrawerOpen: boolean,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        uiContainer: {
            height: '100%',
            width: '100%',
            position: 'fixed',
            zIndex: 5,
            left: 0,
            top: 0,
            overflowX: 'hidden', /* Disable horizontal scroll */
            overflowY: 'hidden', /* Disable vertical scroll */
            pointerEvents: 'none', /* Don't intercept mouse clicks */
        },
        venueDrawer: {
            pointerEvents: 'auto',
        },
        filterBox: {
            pointerEvents: 'auto',
        },
        desktopBottomNav: {
            width: 400,
            pointerEvents: 'auto',
        }
    }),
);

interface PathParams {
    placeId: string
}

function VenueDrawer({placeId}: RouteComponentProps<PathParams>) {
    const venueMap = useSelector(getVenueMapSelector);
    const currentlySelectedVenue = placeId ? venueMap[placeId] : undefined;
    const venueDrawerOpen = !!placeId;
    const dispatch = useDispatch();

    useEffect(() => {
        // I'm unsure why window history is 2 on first page load, but it seems to be.
        if (window.history.length <= 2 && currentlySelectedVenue) {
            dispatch(mapActionTriggered({type: 'centerAndZoomToSingle', location: currentlySelectedVenue.location}));
        }
    }, []);

    const classes = useStyles({
        venueDrawerOpen
    });

    let venueDrawerDesktop = (<div/>);
    let venueDrawerMobile = (<div/>);
    if (currentlySelectedVenue) {
        venueDrawerDesktop = (<VenueDrawerDesktop className={classes.venueDrawer} open={venueDrawerOpen} venue={currentlySelectedVenue}/>);
        venueDrawerMobile = (<VenueDrawerMobile className={classes.venueDrawer} open={venueDrawerOpen} venue={currentlySelectedVenue}/>);
    }
    
    return <>
        <Hidden smDown>
            {venueDrawerDesktop}
        </Hidden>
        <Hidden mdUp>
            {venueDrawerMobile}
        </Hidden>
    </>;
}

export {
    VenueDrawer
};