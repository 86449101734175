import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {
    Button, Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    Switch,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch, useSelector} from 'react-redux';
import {
    addFilter, getSearchedCuisineSelector,
    getVenueSearchResultCount,
    removeFilter,
    venueTypeFilterAdded, venueTypeFilterRemoved
} from '../../store/venuesSlice';
import Divider from '@material-ui/core/Divider';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {filterActiveSelector, getOpenNowFilter, getOrCreateVenueTypeFilter} from '../../store/searchFilters';
import {navigate} from '@reach/router';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: () => ({
            display: 'flex',
            flexDirection: 'column',
            height:'100%',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        }),
        title: {
            fontSize: '1.1rem',
            lineHeight: '1.9',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        bottomSection: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: theme.spacing(2),
        },
        venueCount: {
            textAlign: 'right',
        },
        middleSection: {
            flexGrow: 1,
        },
        form: {
            margin: theme.spacing(3),
            marginBottom: theme.spacing(1),
            alignItems: 'flex-start',
            flexDirection: 'column',
        },
        formControl: {
            margin: theme.spacing(3),
            marginTop: theme.spacing(1)
        },
    }),
);

type Props = {
    className: string | undefined,
    isSidebar: boolean,
}

function FilterBoxContent({className, isSidebar}: Props): JSX.Element {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isFilterActive = useSelector(filterActiveSelector);
    const openNowFilterActive = isFilterActive(getOpenNowFilter(Date.now())) || false;
    const venueSearchResultCount = useSelector(getVenueSearchResultCount);
    const currentCuisine = useSelector(getSearchedCuisineSelector);
    const currentCuisineText = (currentCuisine?.secondary || currentCuisine?.primary)?.replace(/ *\([^)]*\) */g, '');

    function onFilterByTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
        const venueType = event.target.name;
        if (event.target.checked) {
            dispatch(venueTypeFilterAdded(venueType));
        } else {
            dispatch(venueTypeFilterRemoved(venueType));
        }
    }

    const filterBoxes = [
        'Restaurant',
        'Cafe',
        'Bakery',
        'Sweet shop',
        'Takeaway joint',
        'Deli',
        'Community club',
        'Grocer'
    ].map((venueType) => {
        const venueTypeFilter = useSelector(getOrCreateVenueTypeFilter(venueType));
        return <FormControlLabel
            key={venueType}
            control={<Checkbox checked={isFilterActive(venueTypeFilter) || false} onChange={onFilterByTypeChange} name={venueType} />}
            label={venueType}
        />;
    });

    function handleClose() {
        return navigate('/');
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            dispatch(addFilter({filter: getOpenNowFilter(Date.now())}));
        } else {
            dispatch(removeFilter({filter: getOpenNowFilter(Date.now())}));
        }
    };

    return (
        <div className={[classes.root, className].join(' ')}>
            <div>
                <MuiDialogTitle disableTypography>
                    <Typography className={classes.title} variant="h6">
                    Filters
                    </Typography>
                    {isSidebar && <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton> }
                </MuiDialogTitle>
                <Divider/>
            </div>
            <div className={classes.middleSection}>
                <FormGroup row className={classes.form}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={openNowFilterActive}
                                onChange={handleChange}
                                name="openNow"
                                color="primary"
                            />
                        }
                        label="Open Now"
                    />
                </FormGroup>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">What kind of place?</FormLabel>
                    <FormGroup row={true}>
                        {filterBoxes}
                    </FormGroup>
                </FormControl>
            </div>
            <div>
                <Divider/>
                <div className={classes.bottomSection}>
                    {!isSidebar && <Button size={'small'} variant="contained" color="primary" onClick={handleClose}>
                        Show {venueSearchResultCount} {currentCuisineText ? currentCuisineText + ' ' : '' }{venueSearchResultCount === 1 ? 'venue' : 'venues'}
                    </Button> }
                    {isSidebar && <Typography className={classes.venueCount}>
                        {venueSearchResultCount} {venueSearchResultCount === 1 ? 'venue' : 'venues'}
                    </Typography>}
                </div>
            </div>
        </div>
    );
}

export {
    FilterBoxContent
};