import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {Dialog} from '@material-ui/core';
import {FilterBoxContent} from './FilterBoxContent';

const useStyles = makeStyles(() =>
    createStyles({
        root: {

        },
        content: {
        },
    })
);

type Props = {
    open: boolean,
    handleClose: () => void,
}

function FilterBoxDialogMobile({open, handleClose}: Props): JSX.Element {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Dialog fullScreen onClose={handleClose} aria-labelledby="filter-dialog" open={open}>
                <FilterBoxContent className={classes.content} isSidebar={false}/>
            </Dialog>
        </div>
    );
}

export {
    FilterBoxDialogMobile,
};