import React, {useEffect, useState} from 'react';
import {createUseStyles} from 'react-jss';
import {Drawer} from '@material-ui/core';
import {VenueDrawerContent} from './VenueDrawerContent';
import {getSearchedCuisineSelector} from '../../store/venuesSlice';
import {useSelector} from 'react-redux';
import {VenueResponse} from '../../shared-types/responses';
import {getCuisineUrl} from '../../store/urlManagement';
import {navigate} from '@reach/router';

// Create your Styles. Remember, since React-JSS uses the default preset,
// most plugins are available without further configuration needed.
const useStyles = createUseStyles({
    drawer: {
        overflow: 'hidden',
        touchAction: 'none',
        height: '100%',
    },
    dragger: {
        width: '100%',
        height: '5px',
        cursor: 'ns-resize',
        padding: '4px 0 4px 0',
        borderTop: '2px solid #ddd',
        position: 'absolute',
        zIndex: '100',
    },
    dragIndicator: {
        width: 25,
        height: 20,
        left: '50%',
        padding: '4px 0 4px 0',
        position: 'absolute',
    },
    content: {

    },
});

type Props = {
    className: string | undefined,
    open: boolean,
    venue: VenueResponse,
}

const drawerId = 'drawer-id';

function VenueDrawerMobile({className, open, venue}: Props): JSX.Element {
    const classes = useStyles();
    const currentCuisine = useSelector(getSearchedCuisineSelector);

    const [draggingPointer, setDraggingPointer] = useState(-1);
    const [distanceFromPointerToTopOfDrawer, setDistanceFromPointerToTopOfDrawer] = useState<number | null>(null);
    const [height, setHeight] = useState(160);
    const [scrollState, setScrollState] = useState<'scrolling' | 'dragging'>('dragging');
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        document.addEventListener('pointermove', handleMouseMove);
        document.addEventListener('pointerup', handleMouseUp);

        return function cleanup() {
            if (!open && height !== 160) {
                setHeight(160);
            }

            document.removeEventListener('pointermove', handleMouseMove);
            document.removeEventListener('pointerup', handleMouseUp);
        };
    });

    function handleMouseMove(event: PointerEvent) {
        if (event.pointerId !== draggingPointer) {
            return;
        }

        if (distanceFromPointerToTopOfDrawer === null) {
            return;
        }
        const oldHeight = height;
        const heightOfWholePage = document.body.getBoundingClientRect().height;
        const distanceFromTopToPointer = event.clientY;
        const distanceFromPointerToBottomOfPage = heightOfWholePage - distanceFromTopToPointer;
        const newHeight = distanceFromPointerToBottomOfPage + Math.max(distanceFromPointerToTopOfDrawer, 0);

        // If we're not at the top yet, stick to drag mode.
        if (oldHeight <= heightOfWholePage - 4) {
            setScrollState('dragging');
            setHeight(newHeight);
        } else { // We are at the top
            if (scrollTop === 0 && newHeight < oldHeight) {
                // Switch back to dragging if we're scrolled to the top, and we're making the drawer smaller.
                setScrollState('dragging');
                setHeight(newHeight);
            } else {
                setScrollState('scrolling');
            }
        }
    }

    function onContentScroll(event: React.UIEvent<HTMLDivElement, UIEvent>) {
        const target = event.target as Element;
        setScrollTop(target.scrollTop);

        if (target.scrollTop === 0) {
            setScrollState('dragging');
        }
    }

    async function handleMouseUp(event: PointerEvent) {
        if (event.pointerId === draggingPointer) {
            setDraggingPointer(-1);
        }

        if (height < 108) {
            const nextPage = currentCuisine ? getCuisineUrl(currentCuisine) : '/';
            await navigate(nextPage);
        }
    }

    function handleDrag(event: React.PointerEvent<HTMLDivElement>) {
        setDraggingPointer(event.pointerId);
        const drawer = document.getElementById(drawerId);

        if (!drawer) {
            setDistanceFromPointerToTopOfDrawer(null);
            return;
        }

        const distanceFromTopToPointer = event.clientY;
        const distanceFromTopOfDrawerToTopOfPage = drawer.getBoundingClientRect().top;
        const distanceFromPointerToTopOfDrawer = distanceFromTopToPointer - distanceFromTopOfDrawerToTopOfPage;

        setDistanceFromPointerToTopOfDrawer(distanceFromPointerToTopOfDrawer);
    }

    return (
        <div className={className}>
            <Drawer
                anchor={'bottom'}
                open={open}
                variant={'persistent'}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                PaperProps={{
                    style: {
                        height
                    }
                }}
            >
                <div
                    className={classes.drawer}
                    id={drawerId}
                    onPointerDown={event => {
                        handleDrag(event);
                    }}
                >
                    <img className={classes.dragIndicator} src={'/dragbar.png'}/>
                    <div
                        id="dragger"
                        className={classes.dragger}
                    />
                    <VenueDrawerContent
                        className={classes.content}
                        venue={venue}
                        scrollable={scrollState === 'scrolling' ? 'content' : 'none'}
                        onContentScroll={onContentScroll}
                    />
                </div>
            </Drawer>
        </div>
    );
}

export {
    VenueDrawerMobile
};