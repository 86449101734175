// Should be imported from shared types instead.
import {ProtoTypeAccessCookieJson} from '../shared-types/responses';

const PROTOTYPE_ACCESS_COOKIE_KEY = 'prototype_access';
const ANONYMOUS_USER_COOKIE_KEY = 'anonymous_user';

interface Cookie {
    key: string;
    value: string;
}

export interface AccessStatus {
    hasAccess: boolean,
    code?: string,
    until?: Date
}

export interface AnonymousUser {
    id: string,
}

function cookieFromString(cookie: string): Cookie {
    const [key, value] = cookie.split('=', 2);
    return {key, value} as Cookie;
}

function getCookies(): Cookie[] {
    return (document.cookie || '')
        .split('; ')
        .map(cookieFromString);
}

function getAnonymousUserDetails(): AnonymousUser | undefined {
    const anonymousUserCookie: Cookie | undefined = getCookies()
        .find((cookie) => cookie.key === ANONYMOUS_USER_COOKIE_KEY);

    if (!anonymousUserCookie) {
        return undefined;
    }

    return JSON.parse(decodeURIComponent(anonymousUserCookie.value)) as AnonymousUser;
}

function setAnonymousUserDetails(anonymousUser: AnonymousUser): void {
    document.cookie = ANONYMOUS_USER_COOKIE_KEY + '=' + encodeURIComponent(JSON.stringify(anonymousUser)) + '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
}

function accessStatusFromCookieJson(accessStatusJson: ProtoTypeAccessCookieJson | 'Not Found'): AccessStatus {
    if (accessStatusJson === 'Not Found') {
        return {
            hasAccess: false
        };
    }

    const accessUntil: Date = new Date(accessStatusJson.accessUntil);

    const currentTime = new Date();

    return {
        hasAccess: currentTime < accessUntil,
        code: accessStatusJson.code,
        until: accessUntil,
    };
}
function accessStatusFromCookie(cookie: Cookie | undefined): AccessStatus {
    if (!cookie) {
        return {
            hasAccess: false
        };
    }

    const accessStatusJson = JSON.parse(decodeURIComponent(cookie.value)) as ProtoTypeAccessCookieJson;

    return accessStatusFromCookieJson(accessStatusJson);
}

function getAccessStatus(): AccessStatus {
    const accessStatusCookie = getCookies()
        .find((cookie) => cookie.key === PROTOTYPE_ACCESS_COOKIE_KEY);
    return accessStatusFromCookie(accessStatusCookie);
}

export type Platform = 'Cordova' | 'Browser';
const PLATFORM_COOKIE_KEY = 'platform';

function getPlatform(): Platform | null {
    return getCookies()
        .find((cookie) => cookie.key === PLATFORM_COOKIE_KEY)
        ?.value as Platform | undefined || null;
}

function setPlatform(platform: Platform): void {
    document.cookie = PLATFORM_COOKIE_KEY + '=' + platform + '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
}

export {
    getAccessStatus,
    accessStatusFromCookieJson,
    getPlatform,
    setPlatform,
    getAnonymousUserDetails,
    setAnonymousUserDetails
};