import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from './store';
import {combinedSearch, SearchResult} from './searchEngines/combinedSearch';
import {cuisineSearchEngineSelector} from './searchEngines/cuisines';
import {locationSearchEngineSelector} from './searchEngines/location';

interface UiState {
    searchResultsBoxOpen: boolean,
    cuisinesListOpen: boolean,
    searchString: string,
    searchResults?: SearchResult[],
}

const initialState: UiState = {
    searchResultsBoxOpen: false,
    cuisinesListOpen: false,
    searchString: '',
    searchResults: undefined,
};

type SearchStringChangedPayload = PayloadAction<{
   searchString: string,
   searchResults?: SearchResult[],
}>;

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        searchResultsClosed: (state) => {
            state.searchResultsBoxOpen = false;
        },
        searchResultsOpened: (state) => {
            state.searchResultsBoxOpen = true;
        },
        cuisinesListClosed: (state) => {
            state.cuisinesListOpen = false;
        },
        cuisinesListOpened: (state) => {
            state.cuisinesListOpen = true;
        },
        searchStringChanged: (state, searchStringPayload: SearchStringChangedPayload) => {
            state.searchString = searchStringPayload.payload.searchString;
            state.searchResults = searchStringPayload.payload.searchResults;
        },
    },
});

const {searchResultsClosed: innerSearchResultsClosed, searchResultsOpened} = uiSlice.actions;
const {cuisinesListClosed, cuisinesListOpened} = uiSlice.actions;
const {searchStringChanged: innerSearchStringChanged} = uiSlice.actions;

const searchResultsClosed = (): AppThunk => async (dispatch, getState) => {
    const searchResultsOpen = getSearchResultsBoxOpenSelector(getState());
    if (!searchResultsOpen) return;
    dispatch(innerSearchResultsClosed());
};

const searchStringSet = (searchString: string): AppThunk => async (dispatch, getState) => {
    const currentSearchString = getSearchStringSelector(getState());
    if (searchString == currentSearchString) return;
    const cuisineSearchEngine = cuisineSearchEngineSelector(getState());
    const locationSearchEngine = locationSearchEngineSelector(getState());
    const searchResults = combinedSearch(searchString, cuisineSearchEngine, locationSearchEngine);
    dispatch(innerSearchStringChanged({searchString, searchResults}));
};

const uiReducer = uiSlice.reducer;

const getSearchResultsBoxOpenSelector: (state: RootState) => boolean =
    (state: RootState) => state.uiState.searchResultsBoxOpen;

const getCuisinesListOpenSelector: (state: RootState) => boolean =
    (state: RootState) => state.uiState.cuisinesListOpen;

const getSearchStringSelector: (state: RootState) => string =
    (state: RootState) => state.uiState.searchString;

const getSearchResultsSelector: (state: RootState) => SearchResult[] | undefined =
    (state: RootState) => state.uiState.searchResults;

export {
    uiReducer,
    getSearchResultsBoxOpenSelector,
    getCuisinesListOpenSelector,
    searchResultsClosed,
    searchResultsOpened,
    cuisinesListClosed,
    cuisinesListOpened,
    getSearchStringSelector,
    searchStringSet,
    getSearchResultsSelector
};
