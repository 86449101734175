import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    outerDiv: {
        // Outer div takes up all space, even space hidden by native nav bars and such.
        height: window.innerHeight,
        width: '100vw',
    },
    hack: {
        // Hack so that the overlay doesn't overlap with the nav bar.
        height: '-webkit-fill-available !important',
    },
    hack2: {
        // Anything that doesn't support -webkit-fill-available defaults to:
        height: '100vh',
        width: '100vw',

        touchAction: 'none', // Disable pinch and double tap zoom
        // I don't know why this is needed, but without it the child element doesn't
        // take up 100% of the parent's size.
        display: 'flex'
    },
});
/**
    This component is a hack that ensures that the height of the inner div is the height of a phone screen,
    excluding any present browser nav bars, which would otherwise obscure the app.
 */
function PhoneFrame(props: { children: React.ReactNode }): JSX.Element {
    const classes = useStyles();

    return <div className={classes.outerDiv}>
        <div className={classes.hack + ' ' + classes.hack2}>
            {props.children}
        </div>
    </div>;
}

export {
    PhoneFrame
};